export default {
  primary: '#1D51EF',
  primaryVariation: '#E1EBFE',
  primaryVariationForGraph: '#00109e',
  primaryDarkVariation: '#0019E9',
  secondary: '#44D62C',
  secondaryVariation: '#EBFAE8',
  palette2: '#E6D174',
  palette3: '#C13C37',
  palette4: '#E38627',
  greyA100: '#EEEEEE',
  greyA200: '#F4F4F4',
  greyA400: '#BDBDBD',
  greyA500: '#5C5C5C',
  greyA700: '#32313C',
  error: '#EF300E',
  white: '#fff',
  gray0: '#EEEEEE',
  gray1: '#F4F4F4',
  gray2: '#BDBDBD',
  gray3: '#5C5C5C',
  gray4: '#32313C',
  darkSurface: '#1A1926',
};
