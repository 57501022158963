import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from '@material-ui/core';
import styled from 'styled-components';
import {useToasts} from 'react-toast-notifications';

import {addEvaluation, getMeeting} from './meetingActions';
import {useUser} from '../auth/userContext';
import {
  useCurrentMeeting,
  useCurrentMeetingDispatch,
} from './currentMeetingContext';
import colors from '../theme/colors';
import routes from '../navigation/routes';
import I18n from '../translations/i18n';
import isDev from '../config/isDev';
import NonFilledStar from './stars/NonFilledStar';
import FilledStar from './stars/FilledStar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {useShowMenuDispatch} from '../navigation/showMenuContext';

const starSize = 60;

export default () => {
  const history = useHistory();
  const {addToast} = useToasts();

  const {uid: meetingUid} = useParams();

  const dispatchCurrentMeeting = useCurrentMeetingDispatch();
  const meeting = useCurrentMeeting();
  const user = useUser();
  const dispatchShowMenu = useShowMenuDispatch();

  const [step, setStep] = useState(0);
  const [rating, setRating] = useState(1);
  const [determinantCursor, setDeterminantCursor] = useState('');
  const [comment, setComment] = useState(isDev ? 'A nice comment' : '');

  useEffect(() => {
    dispatchShowMenu(false);
  }, []);

  useEffect(() => {
    async function initData() {
      const firestoreMeeting = await getMeeting(meetingUid);

      dispatchCurrentMeeting(firestoreMeeting);
    }
    initData();
  }, []);

  const onStarPress = newRating => {
    setRating(newRating);
  };

  const ContinueButton = () => {
    const onClick = () => {
      setStep(step + 1);
    };
    return (
      <Button
        onClick={onClick}
        style={{
          backgroundColor: colors.secondary,
          color: 'white',
          fontSize: 20,
          fontFamily: 'Poppins',
        }}>
        {I18n.t('continue')}
      </Button>
    );
  };

  const onClickTerminate = async () => {
    try {
      const formattedUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        photoURL: user.photoURL,
        userUid: user.uid,
      };

      await addEvaluation(
        formattedUser,
        meeting.uid,
        meeting.facilitatorUid,
        rating,
        comment,
        determinantCursor,
      );

      setStep(3);

      setTimeout(() => {
        history.push(routes.home);
      }, 3000);
    } catch (e) {
      console.error('e', e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const StepDots = () => {
    return (
      <StepDotsContainer>
        <div onClick={() => setStep(0)}>
          <Circle
            style={step === 0 ? {backgroundColor: colors.secondary} : {}}
          />
        </div>
        <div onClick={() => setStep(1)}>
          <Circle
            style={step === 1 ? {backgroundColor: colors.secondary} : {}}
          />
        </div>
        <div onClick={() => setStep(2)}>
          <Circle
            style={step === 2 ? {backgroundColor: colors.secondary} : {}}
          />
        </div>
      </StepDotsContainer>
    );
  };

  const SHeader = () => {
    return (
      <div style={{zIndex: 9}}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              paddingTop: 12,
            }}>
            <STitle>{I18n.t('evaluation')}</STitle>

            <span style={{fontWeight: 'bold', marginRight: 20, fontSize: 20}}>
              {I18n.t('step')}{' '}
              <span style={{color: colors.secondary, fontSize: 20}}>
                {step + 1}
              </span>{' '}
              {I18n.t('over3')}
            </span>
          </div>
        </div>

        <SHr />

        <div
          style={{
            color: colors.primary,
            textAlign: 'center',
            fontSize: 20,
            marginTop: 15,
          }}>
          <NietzscheText>{I18n.t('evaluateNietzsche')}</NietzscheText>
        </div>
      </div>
    );
  };

  if (step === 0) {
    return (
      <>
        <SHeader />

        <Container>
          <WhiteText>{I18n.t('howWouldYouRate')}</WhiteText>

          <Stars>
            {[1, 2, 3, 4, 5].map(e => {
              return (
                <div onClick={() => onStarPress(e)} key={e}>
                  {e <= rating ? (
                    <FilledStar
                      style={{
                        height: starSize,
                        width: starSize,
                        color: colors.secondary,
                        margin: 12,
                      }}
                    />
                  ) : (
                    <NonFilledStar
                      style={{height: starSize, width: starSize, margin: 12}}
                    />
                  )}
                </div>
              );
            })}
          </Stars>

          <StepsAndButton>
            <StepDots />

            <ContinueButton />
          </StepsAndButton>
        </Container>
      </>
    );
  }

  if (step === 1) {
    return (
      <>
        <SHeader />

        <Container>
          <WhiteText>{I18n.t('whatWasDeterminingIndicator')}</WhiteText>

          <CursorsButtons>
            {Object.keys(meeting.dialogTable.cursors).map(cursor => {
              return (
                <CursorsButtonsRow key={cursor}>
                  {determinantCursor === cursor ? (
                    <Button
                      style={{
                        marginTop: 35,
                        backgroundColor: colors.secondary,
                        color: 'white',
                        width: 180,
                        height: 70,
                        fontWeight: 'bold',
                      }}>
                      {I18n.t(cursor)}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setDeterminantCursor(cursor)}
                      style={{
                        marginTop: 35,
                        width: 180,
                        backgroundColor: colors.gray1,
                        height: 70,
                      }}>
                      {I18n.t(cursor)}
                    </Button>
                  )}
                </CursorsButtonsRow>
              );
            })}
          </CursorsButtons>

          <StepsAndButton>
            <StepDots />

            <ContinueButton />
          </StepsAndButton>
        </Container>
      </>
    );
  }

  if (step === 2) {
    return (
      <>
        <SHeader />

        <Container>
          <WhiteText>{I18n.t('whatConstructiveFeedback')}</WhiteText>

          <TextareaAutosize
            rowsMin={10}
            placeholder=""
            onChange={v => setComment(v.target.value)}
            value={comment}
            style={{
              borderColor: colors.secondary,
              borderRadius: 4,
              width: window.innerWidth * 0.6,
            }}
          />

          <StepsAndButton>
            <StepDots />

            <Button
              style={{
                backgroundColor: colors.secondary,
                color: 'white',
                fontSize: 20,
              }}
              onClick={onClickTerminate}>
              {I18n.t('terminate')}
            </Button>
          </StepsAndButton>
        </Container>
      </>
    );
  }

  return (
    <ThanksContainer style={{justifyContent: 'center'}}>
      <Circle
        style={{
          position: 'absolute',
          top: 20,
          left: 20,
          backgroundColor: colors.secondary,
        }}
      />
      <Circle
        style={{
          position: 'absolute',
          top: 20,
          left: window.innerWidth / 2 - 10,
          backgroundColor: 'white',
        }}
      />
      <Circle
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          backgroundColor: colors.secondary,
        }}
      />
      <ThanksSubContainer>
        <ThanksText>{I18n.t('thanks')}</ThanksText>
      </ThanksSubContainer>

      <Circle
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          backgroundColor: colors.secondary,
        }}
      />
      <Circle
        style={{
          position: 'absolute',
          bottom: 20,
          left: window.innerWidth / 2 - 10,
          backgroundColor: 'white',
        }}
      />
      <Circle
        style={{
          position: 'absolute',
          bottom: 20,
          right: 20,
          backgroundColor: colors.secondary,
        }}
      />
    </ThanksContainer>
  );
};

const Stars = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  height: ${window.innerHeight + 'px'};
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThanksContainer = styled.div`
  height: ${window.innerHeight + 'px'};
  background-color: ${colors.primary};
`;

const ThanksSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${window.innerHeight + 'px'};
`;

const WhiteText = styled.p`
  color: white;
  font-size: ${window.innerWidth < 800 ? '26px' : '40px'};
  padding-top: 38px;
  padding-bottom: 10px;
  text-align: center;
  display: block;
  font-family: Colonel, sans-serif;
`;

const ThanksText = styled.p`
  color: white;
  font-size: 70px;
  text-align: center;
  display: block;
  font-family: Colonel, sans-serif;
`;

const Circle = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${colors.gray2};
  border-radius: 5px;
  margin: 0 5px;
`;

const StepDotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

const StepsAndButton = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.primary};
`;

const STitle = styled.span`
  margin-left: 20px;
  font-size: 26px;
  font-family: Colonel, sans-serif;
`;

const NietzscheText = styled.span`
  color: ${colors.primary};
  text-align: center;
  font-family: Colonel, sans-serif;
`;

const CursorsButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CursorsButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;
