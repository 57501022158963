import React, {useRef, useState} from 'react';
import {Button, Card, TextField} from '@material-ui/core';
import {CSVReader} from 'react-papaparse';
import {useToasts} from 'react-toast-notifications';

import addUsersToFirm from '../firms/addUsersToFirm';
import I18n from '../translations/i18n';
import colors from '../theme/colors';
import {useUser} from '../auth/userContext';
import sendInvitationMail from '../mails/sendInvitationMail';

export default ({setIsLoading, reloadPage}) => {
  const {addToast} = useToasts();

  const me = useUser();

  const buttonRef = useRef();

  const [newUserEmail, setNewUserEmail] = useState<string>('');

  const addUser = async () => {
    try {
      if (!me?.firmUid) {
        addToast(I18n.t('noFirmRegistered'), {appearance: 'error'});
      } else {
        await Promise.all([
          sendInvitationMail(newUserEmail, I18n.locale, me?.firmName),
          addUsersToFirm([newUserEmail], me!.firmUid),
        ]);
        setNewUserEmail('');
        addToast(I18n.t('invitationSent'), {appearance: 'success'});
        reloadPage();
      }
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const handleOpenDialog = e => {
    if (buttonRef.current) {
      // @ts-ignore
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async data => {
    setIsLoading(true);
    const emails = data[0].data;

    if (!me?.firmUid) {
      addToast(I18n.t('noFirmRegistered'), {appearance: 'error'});
    }

    await addUsersToFirm(emails, me!.firmUid!);
    return reloadPage();
  };

  const handleOnError = () => {
    addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
  };

  return (
    <Card
      style={{
        flex: 1,
        marginLeft: 25,
        marginRight: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
      <div>
        <TextField
          style={{width: 260}}
          value={newUserEmail}
          placeholder={I18n.t('newUser')}
          onChange={v => setNewUserEmail(v.target.value)}
        />

        <Button
          style={{marginLeft: 40}}
          disabled={newUserEmail === ''}
          onClick={addUser}
          variant="contained"
          color="primary">
          {I18n.t('sendAnInvitation')}
        </Button>
      </div>

      <div style={{display: 'flex'}}>
        <CSVReader
          //            @ts-ignore
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          style={{
            dropArea: {
              borderColor: colors.primary,
              borderRadius: 5,
              height: 40,
              width: 260,
              backgroundColor: 'white',
            },
            dropAreaActive: {
              borderColor: colors.palette3,
            },
            fileSizeInfo: {
              display: 'none',
            },
            dropFile: {
              display: 'none',
            },
          }}>
          {I18n.t('dropFileHere')}
        </CSVReader>
        <Button
          style={{marginLeft: 40}}
          onClick={handleOpenDialog}
          variant="contained"
          color="primary">
          {I18n.t('importACSVFile')}
        </Button>
      </div>
    </Card>
  );
};
