import React from 'react';
import colors from '../theme/colors';
import I18n from '../translations/i18n';

export const errorBoundaryHandler = (error, componentStack) => {
  console.log('Error from boundary handler', error);
};

export const ErrorBoundaryFallback = () => {
  return (
    <div style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
      <p style={{fontSize: 16, color: colors.gray4}}>
        {I18n.t('sorryAnErrorOccurred')}
      </p>
    </div>
  );
};
