import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';

import I18n from '../translations/i18n';
import client from '../util/fetch';
import {UPDATE_IS_FACILITATOR} from '../config/cloudFunctionsUrls';
import {useUser} from '../auth/userContext';

const IsUserFacilitatorModal = ({
  userToToggleFacilitator,
  setUserToToggleFacilitator,
  users,
  setUsers,
  setNumberOfAttributedLicences,
  setNumberOfAvailableLicences,
}) => {
  const {addToast} = useToasts();

  const me = useUser();

  const toggleIsFacilitator = async () => {
    try {
      const shouldBeFacilitatorNow = !userToToggleFacilitator?.isFacilitator;
      await client(
        UPDATE_IS_FACILITATOR(
          me.uid,
          userToToggleFacilitator!.uid,
          shouldBeFacilitatorNow,
        ),
      );

      const newUsers = users.map(user => {
        if (user.uid === userToToggleFacilitator!.uid) {
          return {...user, isFacilitator: shouldBeFacilitatorNow};
        }
        return user;
      });

      setUsers(newUsers);

      setNumberOfAttributedLicences(l => l + (shouldBeFacilitatorNow ? 1 : -1));
      setNumberOfAvailableLicences(l => l + (shouldBeFacilitatorNow ? -1 : +1));

      addToast(I18n.t('userUpdated'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setUserToToggleFacilitator(undefined);
    }
  };

  return (
    <Dialog
      open={Boolean(userToToggleFacilitator)}
      onClose={() => setUserToToggleFacilitator(undefined)}>
      <DialogTitle>
        {_.upperFirst(userToToggleFacilitator?.firstName)}{' '}
        {_.upperFirst(userToToggleFacilitator?.lastName)}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}>
          {userToToggleFacilitator?.isFacilitator
            ? I18n.t('confirmRemoveFacilitatorMode')
            : I18n.t('confirmUpdateToFacilitator')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setUserToToggleFacilitator(undefined)}
          color="primary">
          {I18n.t('cancel')}
        </Button>
        <Button onClick={toggleIsFacilitator} type="submit" color="primary">
          {I18n.t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IsUserFacilitatorModal;
