import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';

import colors from '../theme/colors';
import CircleLetter from './CircleLetter';

const ThumbnailOrCircleLetter = ({
  user,
  color,
  style = {},
  onClick = false,
}) => {
  return user?.photoURL ? (
    <Avatar
      src={user.photoURL}
      style={{width: 60, height: 60, ...style}}
      onClick={onClick ? onClick : undefined}
    />
  ) : (
    <CircleLetter
      style={style}
      size={60}
      color={color ? color : colors.primary}
      letter={
        user?.firstName
          ? _.upperFirst(user.firstName[0]) + _.upperFirst(user.lastName[0])
          : ''
      }
    />
  );
};

export default ThumbnailOrCircleLetter;
