import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@material-ui/core';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';
import styled from 'styled-components';
import {Parser} from 'json2csv';
import {saveAs} from 'file-saver';

import FullPageSpinner from '../util/FullPageSpinner';
import User from '../users/User';
import {getAllUsers} from '../users/usersActions';
import {Face, Warning} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import I18n from '../translations/i18n';
import client from '../util/fetch';
import {
  DELETE_USER_URL,
  UPDATE_IS_FACILITATOR,
} from '../config/cloudFunctionsUrls';
import {useUser} from '../auth/userContext';
import colors from '../theme/colors';
import sendInvitationMail from '../mails/sendInvitationMail';

export default () => {
  const {addToast} = useToasts();

  const me = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [userToDelete, setUserToDelete] = useState<User | undefined>();
  const [userToToggleFacilitator, setUserToToggleFacilitator] = useState<
    User | undefined
  >();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    async function initData() {
      setUsers(await getAllUsers());
      setIsLoading(false);
    }
    initData();
  }, []);

  const exportUsersAsCSV = async () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(
      users.map(user => ({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        isFacilitator: user.isFacilitator,
        ...(user.creationDate && {creationDate: user.creationDate.toDate()}),
      })),
    );

    const blob = new Blob([csv], {type: 'text/csv'});
    saveAs(blob, 'users.csv');
  };

  const addUser = async () => {
    try {
      await sendInvitationMail(newUserEmail, I18n.locale);
      setNewUserEmail('');
      addToast(I18n.t('invitationSent'), {appearance: 'success'});
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  if (isLoading) return <FullPageSpinner />;

  const deleteUser = async userToDeleteUid => {
    try {
      setUserToDelete(undefined);
      await client(DELETE_USER_URL(me.uid, userToDeleteUid));
      const newUsers = users.filter(user => user.uid !== userToDeleteUid);
      setUsers(newUsers);
      addToast(I18n.t('userDeleted'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const toggleIsFacilitator = async () => {
    try {
      const shouldBeFacilitatorNow = !userToToggleFacilitator?.isFacilitator;
      await client(
        UPDATE_IS_FACILITATOR(
          me.uid,
          userToToggleFacilitator!.uid,
          shouldBeFacilitatorNow,
        ),
      );

      const newUsers = users.map(user => {
        if (user.uid === userToToggleFacilitator!.uid) {
          return {...user, isFacilitator: shouldBeFacilitatorNow};
        }
        return user;
      });

      setUsers(newUsers);
      setUserToToggleFacilitator(undefined);
      addToast(I18n.t('userUpdated'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const className = touched ? 'touchableOpacityTouched' : 'touchableOpacity';

  const handleMouseUp = () => {
    setTimeout(() => {
      setTouched(false);
    }, 1);
  };

  const toggleTouched = () => {
    setTouched(t => !t);
  };

  const DeleteUserModal = () => {
    return (
      <Dialog
        open={Boolean(userToDelete)}
        onClose={() => setUserToDelete(undefined)}>
        <DialogTitle>
          {I18n.t('deletionOfUser')} {_.upperFirst(userToDelete?.firstName)}{' '}
          {_.upperFirst(userToDelete?.lastName)}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              color: colors.error,
            }}>
            <Warning color={'error'} style={{marginRight: 10}} />
            {I18n.t('userDeletionWarning')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setUserToDelete(undefined)} color="primary">
            {I18n.t('cancel')}
          </Button>
          <Button
            style={{color: colors.error}}
            onClick={() => deleteUser(userToDelete?.uid)}
            type="submit"
            color="primary">
            {I18n.t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ToggleIsFacilitatorModal = () => {
    return (
      <Dialog
        open={Boolean(userToToggleFacilitator)}
        onClose={() => setUserToToggleFacilitator(undefined)}>
        <DialogTitle>
          {_.upperFirst(userToToggleFacilitator?.firstName)}{' '}
          {_.upperFirst(userToToggleFacilitator?.lastName)}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
            }}>
            {userToToggleFacilitator?.isFacilitator
              ? I18n.t('confirmRemoveFacilitatorMode')
              : I18n.t('confirmUpdateToFacilitator')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setUserToToggleFacilitator(undefined)}
            color="primary">
            {I18n.t('cancel')}
          </Button>
          <Button onClick={toggleIsFacilitator} type="submit" color="primary">
            {I18n.t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <DeleteUserModal />
      <ToggleIsFacilitatorModal />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginRight: 20,
          }}>
          <TextField
            value={newUserEmail}
            placeholder={I18n.t('newUser')}
            onChange={v => setNewUserEmail(v.target.value)}
          />

          <Button
            style={{marginLeft: 40}}
            disabled={newUserEmail === ''}
            onClick={addUser}
            variant="contained"
            color="primary">
            {I18n.t('sendAnInvitation')}
          </Button>
        </div>

        <SButton
          color="secondary"
          onClick={exportUsersAsCSV}
          variant="contained">
          <span style={{color: 'white', fontWeight: 'bold'}}>
            {I18n.t('exportUsersAsCSV')}
          </span>
        </SButton>
      </div>

      <List dense style={{maxWidth: 500, marginTop: 30}}>
        {users.map(user => {
          return (
            <ListItem
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              key={user.uid}>
              <svg
                onMouseUp={handleMouseUp}
                className={className}
                onMouseDown={toggleTouched}
                onClick={() => setUserToToggleFacilitator(user)}
                style={{
                  width: 26,
                  height: 26,
                  color: user.isFacilitator ? colors.primary : colors.gray2,
                  marginRight: 26,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512">
                <path
                  fill="currentColor"
                  d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                />
              </svg>

              <ListItemIcon>
                {user.photoURL ? (
                  <ListItemAvatar>
                    <Avatar src={user.photoURL} />
                  </ListItemAvatar>
                ) : (
                  <span
                    style={{
                      marginLeft: 8,
                      marginBottom: -4,
                      alignSelf: 'center',
                    }}>
                    <Face />
                  </span>
                )}
              </ListItemIcon>

              <ListItemText
                primary={user.firstName + ' ' + user.lastName}
                secondary={user.email}
              />

              <ListItemSecondaryAction onClick={() => setUserToDelete(user)}>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
                <Icon className="fa fa-plus-circle" />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

const SButton = styled(Button)`
  margin-left: 20px;
  margin-bottom: 20px;
  width: max-content;
`;
