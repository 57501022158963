import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import uuid from 'uuid';
import {useToasts} from 'react-toast-notifications';
import {Create} from '@material-ui/icons';
import moment from 'moment';
import 'moment/locale/fr';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import {useForm} from 'react-hook-form';

import {StyledTableCell, StyledTableRow} from '../theme/tableTheme';
import Firm from '../firms/Firm';
import {
  addFirm as addFirmAction,
  getAllFirms,
  updateFirm,
} from '../firms/firmsActions';
import FullPageSpinner from '../util/FullPageSpinner';
import I18n from '../translations/i18n';

export default () => {
  const {addToast} = useToasts();

  const {watch, register, reset} = useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [firms, setFirms] = useState<Firm[]>([]);
  const [newFirmName, setNewFirmName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firmToUpdate, setFirmToUpdate] = useState<Firm | undefined>();

  useEffect(() => {
    async function initData() {
      setFirms(await getAllFirms());
      setIsLoading(false);
    }
    initData();
  }, []);

  const addFirm = async () => {
    const firm: Firm = {
      uid: uuid.v4(),
      name: newFirmName,
      createdDate: new Date(),
      numberOfLicences: 0,
      numberOfAttributedLicences: 0,
    };
    try {
      await addFirmAction(firm);
      setNewFirmName('');
      setFirms(f => f.concat([firm]));
      setIsModalOpen(true);
      setFirmToUpdate(firm);
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const classes = useStyles();

  if (isLoading) return <FullPageSpinner />;

  const onClickUpdate = firm => {
    setIsModalOpen(true);
    setFirmToUpdate(firm);
  };

  const submitFirmUpdate = async () => {
    const stringReferentsEmail = watch('referentsEmail');
    let referentsEmail = [];

    try {
      referentsEmail = _.uniq(stringReferentsEmail.split(','))
        .map(email => email.trim())
        .filter(email => email);
    } catch (e) {
      console.error(e);
      return;
    }

    try {
      const updatedFirm = {
        ...firmToUpdate,
        ...{
          name: watch('name'),
          referentsEmail,
          numberOfLicences: watch('numberOfLicences'),
        },
      } as Firm;

      await updateFirm(firmToUpdate?.uid, updatedFirm);

      const newFirms = firms.map(firm => {
        if (firm.uid === firmToUpdate?.uid) {
          return updatedFirm;
        }
        return firm;
      });

      setFirms(newFirms);
      reset();
      addToast(I18n.t('firmModified'), {appearance: 'success'});
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsModalOpen(false);
    }
  };

  const UpdateFirmModal = () => {
    return (
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>
          {I18n.t('updateTheFirm')} {firmToUpdate?.name}
        </DialogTitle>

        <DialogContent>
          <form>
            <TextField
              inputRef={register}
              name="name"
              defaultValue={firmToUpdate?.name}
              autoFocus
              margin="dense"
              label={I18n.t('name')}
              fullWidth
            />
            <TextField
              inputRef={register}
              name="referentsEmail"
              type="email"
              defaultValue={firmToUpdate?.referentsEmail}
              margin="dense"
              label={I18n.t('referentEmail')}
              fullWidth
            />
            <TextField
              inputRef={register}
              type="number"
              name="numberOfLicences"
              defaultValue={firmToUpdate?.numberOfLicences}
              margin="dense"
              label={I18n.t('numberOfLicences')}
              fullWidth
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            {I18n.t('cancel')}
          </Button>
          <Button onClick={submitFirmUpdate} type="submit" color="primary">
            {I18n.t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <UpdateFirmModal />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          marginBottom: 26,
        }}>
        <TextField
          value={newFirmName}
          placeholder={I18n.t('newFirm')}
          onChange={v => setNewFirmName(v.target.value)}
        />

        <Button
          style={{marginLeft: 40}}
          disabled={!newFirmName}
          onClick={addFirm}
          variant="contained"
          color="primary">
          {I18n.t('add')}
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">{I18n.t('name')}</StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('referents')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('numberOfLicences')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('creationDate')}
              </StyledTableCell>

              <StyledTableCell align="center">Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {firms.map(firm => (
              <StyledTableRow key={uuid.v4()}>
                <StyledTableCell align="center" component="th" scope="row">
                  {firm.name}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {firm.referentsEmail?.join(', ')}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {firm.numberOfLicences}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {moment(firm.createdDate).format('LLL')}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Create onClick={() => onClickUpdate(firm)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
