import React, {createContext, useEffect, useState} from 'react';

import FullPageSpinner from '../util/FullPageSpinner';
import User, {devUser} from '../users/User';
import {getUser} from '../users/usersActions';
import iAmReferentOfAFirm from '../referentApp/iAmReferentOfAFirm';
import firebase from 'firebase';
import useAuth from './authContext';
import uuid from 'uuid';

type SetUser = (partialUser: any) => void;

const UserContext = createContext<User | null | undefined>(undefined);
const UserDispatchContext = createContext({} as SetUser);

function UserProvider({children}) {
  const myAuth = useAuth();
  const [userDetails, setUserDetails] = useState<User | null | undefined>();
  const setUser: SetUser = newData => {
    setUserDetails({...userDetails, ...newData});
  };

  const [isLoading, setIsLoading] = useState(true);

  const userOrGuest = async uid => {
    const user = await getUser(uid);
    if (user) return user;

    const guestUid = uuid.v4();
    const guestNumber = Math.floor(Math.random() * (999 - 1 + 1) + 1);
    return {
      uid: guestUid,
      email: 'guest' + guestUid,
      firstName: 'guest',
      lastName: guestNumber.toString(),
    };
  };

  useEffect(() => {
    async function initData() {
      const isDev = false;

      if (isDev) {
        setUserDetails(devUser);
        setIsLoading(false);
      } else {
        const authUser = firebase.auth()?.currentUser;

        if (authUser) {
          const user = await userOrGuest(authUser.uid);
          const iAmReferent = await iAmReferentOfAFirm(user);

          if (iAmReferent) {
            console.log('not normal check userContext');
            // @ts-ignore
            setUserDetails({
              isReferent: Boolean(iAmReferent),
              firmUid: iAmReferent,
              ...user,
              // ...authUser,
              photoURL: authUser.photoURL,
            });
          } else {
            // @ts-ignore
            setUserDetails({
              isReferent: Boolean(iAmReferent),
              ...user,
              // ...authUser,
              photoURL: authUser.photoURL,
            });
          }
        }

        setIsLoading(false);
      }
    }
    initData();
  }, [myAuth]);

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUser}>
        {isLoading ? <FullPageSpinner /> : children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error('useUserState must be used within a UserProvider');
  }

  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);

  if (!context) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }

  return context;
}

export {UserProvider, useUser, useUserDispatch};
