import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {Create} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {useForm} from 'react-hook-form';
import {useToasts} from 'react-toast-notifications';

import {StyledTableCell, StyledTableRow} from '../theme/tableTheme';
import {getAllCursors, updateCursor} from '../meetings/meetingActions';
import I18n from '../translations/i18n';

export default () => {
  const classes = useStyles();
  const {addToast} = useToasts();
  const {watch, register, reset} = useForm();

  const [allCursors, setAllCursors] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cursorToUpdate, setCursorToUpdate] = useState<any>();

  useEffect(() => {
    async function initData() {
      setAllCursors(
        (await getAllCursors()).sort((a, b) => b.counter - a.counter),
      );
    }
    initData();
  }, []);

  const onClickUpdate = cursor => {
    setCursorToUpdate(cursor);
    setIsModalOpen(true);
  };

  const submitCursorUpdate = async () => {
    const frenchCursorInformation = watch('frenchCursorInformation');
    const englishCursorInformation = watch('englishCursorInformation');

    try {
      const information = {
        information: {
          en: englishCursorInformation,
          fr: frenchCursorInformation,
        },
      };
      const updatedCursor = {...cursorToUpdate, ...information};

      await updateCursor(cursorToUpdate?.uid, information);

      const newCursors = allCursors.map(cursor => {
        if (cursor.uid === cursorToUpdate?.uid) {
          return updatedCursor;
        }
        return cursor;
      });

      setAllCursors(newCursors);
      reset();
      addToast(I18n.t('cursorModified'), {appearance: 'success'});
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsModalOpen(false);
    }
  };

  const UpdateCursorModal = () => {
    return (
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>
          {I18n.t('updateTheCursor')}
          {cursorToUpdate?.uid && I18n.t(cursorToUpdate?.uid)}
        </DialogTitle>

        <DialogContent>
          <form>
            <TextField
              inputRef={register}
              name="frenchCursorInformation"
              defaultValue={cursorToUpdate?.information?.fr}
              margin="dense"
              label={I18n.t('frenchCursorInformation')}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="englishCursorInformation"
              defaultValue={cursorToUpdate?.information?.en}
              margin="dense"
              label={I18n.t('englishCursorInformation')}
              fullWidth
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            {I18n.t('cancel')}
          </Button>
          <Button onClick={submitCursorUpdate} type="submit" color="primary">
            {I18n.t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <UpdateCursorModal />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">
                {I18n.t('cursor')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('counter')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('frenchCursorInformation')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('englishCursorInformation')}
              </StyledTableCell>

              <StyledTableCell align="center">Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {allCursors.map(cursor => (
              <StyledTableRow key={cursor.uid}>
                <StyledTableCell align="center" component="th" scope="row">
                  {I18n.t(cursor.cursorKey)}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cursor.counter}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cursor.information?.fr}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cursor.information?.en}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Create onClick={() => onClickUpdate(cursor)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
