export default {
  home: '/',
  accountSettings: '/account-settings',
  createAccount: '/create-account',
  confidentiality: '/politique-de-confidentialite',
  meetingPage: '/meeting-page/',
  meetingPageMatcher: '/meeting-page/:uid',
  meetingEvaluation: '/meeting-evaluation/',
  evaluationEvaluationMatcher: '/meeting-evaluation/:uid',
  survey: '/survey',
  surveyResults: '/surveyResults',
};
