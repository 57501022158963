import {makeStyles} from '@material-ui/core/styles';
import colors from '../theme/colors';

const useStyles = makeStyles(theme => ({
  loginContainer: {
    width: 450,
    height: 600,
    marginTop: -100,
  },
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: theme.palette.grey.A100,
    height: '100%',
    width: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  header: {
    backgroundColor: theme.palette.info.main,
    height: 120,
    elevation: 3,
    marginBottom: 20,
  },
  headerTitle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 'bold',
    fontFamily: 'Colonel',
  },
  cardContent: {
    height: 300,
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  registerCardContent: {
    height: 300,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    flex: 1,
    width: '100%',
  },
  icon: {
    marginRight: 30,
  },
  button: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
    fontWeight: 'bold',
  },
  registerButton: {
    backgroundColor: colors.gray1,
    color: 'black',
    fontWeight: 'bold',
  },
}));

export default useStyles;
