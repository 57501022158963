import {createMuiTheme} from '@material-ui/core';
import colors from './colors';
// @ts-ignore
// import PoppinsLight from './fonts/Poppins-Light.ttf';
//
// const poppinsLight = {
//   fontFamily: 'PoppinsLight',
//   fontStyle: 'semi-bold',
//   fontDisplay: 'swap',
//   fontWeight: '600',
//   src: `
//    local('PoppinsLight'),
//    local('PoppinsLight-SemiBold'),
//    url(${PoppinsLight}) format('ttf')
//  `,
//   unicodeRange:
//     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'Colonel', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Colonel', 'sans-serif'].join(','),
    },
  },

  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       // @ts-ignore
  //       '@font-face': [poppinsLight],
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    grey: {
      A100: colors.greyA100,
      A200: colors.greyA200,
      A400: colors.greyA400,
      A700: colors.greyA700,
    },
    info: {
      main: colors.primary,
    },
    error: {
      main: colors.error,
    },
  },
});

export default theme;
