import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';

export default ({letter, size, color}) => {
  const Circle = styled.div`
    height: ${size + 'px'};
    width: ${size + 'px'};
    background-color: ${color};
    border-radius: ${size / 2 + 'px'};
    border-color: ${color};
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Circle>
      <div style={{color: 'white', fontSize: size / 3, fontWeight: 'bold'}}>
        {letter}
      </div>
    </Circle>
  );
};
