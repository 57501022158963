import React from 'react';
import Slider from './Slider';

export default React.memo(
  ({
    entry,
    setSliderValue,
    label,
    trackBackgroundColor = 'white',
    thumbColor,
    plusColor,
  }) => {
    return (
      <Slider
        label={label}
        entry={entry}
        setSliderValue={setSliderValue}
        trackBackgroundColor={trackBackgroundColor}
        thumbColor={thumbColor}
        plusColor={plusColor}
      />
    );
  },
);
