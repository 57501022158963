import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';

import I18n from '../translations/i18n';
import colors from '../theme/colors';
import User from '../users/User';

const DeleteUserModal = ({users, setUsers}) => {
  const {addToast} = useToasts();

  const [userToDelete, setUserToDelete] = useState<User | undefined>();

  const deleteUser = async userToDeleteUid => {
    try {
      setUserToDelete(undefined);
      // await client(DELETE_USER_URL(me.uid, userToDeleteUid));
      const newUsers = users.filter(user => user.uid !== userToDeleteUid);
      setUsers(newUsers);
      addToast(I18n.t('userDeleted'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  return (
    <Dialog
      open={Boolean(userToDelete)}
      onClose={() => setUserToDelete(undefined)}>
      <DialogTitle>
        {I18n.t('deletionOfUser')} {_.upperFirst(userToDelete?.firstName)}{' '}
        {_.upperFirst(userToDelete?.lastName)}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            color: colors.error,
          }}>
          <Warning color={'error'} style={{marginRight: 10}} />
          {I18n.t('userDeletionWarning')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setUserToDelete(undefined)} color="primary">
          {I18n.t('cancel')}
        </Button>
        <Button
          style={{color: colors.error}}
          onClick={() => deleteUser(userToDelete?.uid)}
          type="submit"
          color="primary">
          {I18n.t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
