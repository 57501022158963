import {useEffect, useState} from 'react';
import User, {devUser} from '../users/User';
import firebase from 'firebase';

const useAuth = (): User | null | undefined => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    // const isDev =
    //   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const isDev = false;

    if (isDev) {
      setUser(devUser);
      setInitializing(false);
      return;
    } else {
      const onAuthStateChanged = newUser => {
        setUser(newUser);
        setInitializing(false);
      };

      return firebase.auth().onAuthStateChanged(onAuthStateChanged);
    }
  }, [initializing]);

  if (initializing) return null;

  return user;
};

export default useAuth;
