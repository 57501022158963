import React from 'react';
import {ThemeProvider} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import 'moment/locale/fr';
import firebase from 'firebase';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ToastProvider} from 'react-toast-notifications';

import './util/ReactotronConfig';
import theme from './theme/theme';
import './theme/styles.css';
import config from './firebase/firebaseConfig';
import AuthenticatedApp from './navigation/AuthenticatedApp';
import UnauthenticatedApp from './navigation/UnauthenticatedApp';
import useAuth from './auth/authContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import {UserProvider} from './auth/userContext';
import MyCustomToast from './toasts/CustomToast';
import {ShowMenuProvider} from './navigation/showMenuContext';

function App() {
  moment.locale('fr');

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();
  }

  const user = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider
        components={{Toast: MyCustomToast}}
        autoDismiss
        autoDismissTimeout={2500}
        placement="bottom-right">
        <CssBaseline />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Metagora</title>
          <meta name="description" content="Metagora" />
        </Helmet>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          {user ? (
            <ShowMenuProvider>
              <UserProvider>
                <AuthenticatedApp />
              </UserProvider>
            </ShowMenuProvider>
          ) : (
            <UnauthenticatedApp />
          )}
        </MuiPickersUtilsProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
