import React, {createContext, useContext, useState} from 'react';

const ShowMenuContext = createContext(false);
const ShowMenuDispatchContext = createContext();

function ShowMenuProvider({children}) {
  const [showMenu, setShowMenu] = useState(true);

  return (
    <ShowMenuContext.Provider value={showMenu}>
      <ShowMenuDispatchContext.Provider value={setShowMenu}>
        {children}
      </ShowMenuDispatchContext.Provider>
    </ShowMenuContext.Provider>
  );
}

function useShowMenu() {
  // Impossible since context can be false, should be an enum in order to user this:
  // if (!context) {
  //   throw new Error('useShowMenuState must be used within a ShowMenuProvider');
  // }

  return useContext(ShowMenuContext);
}

function useShowMenuDispatch() {
  return useContext(ShowMenuDispatchContext);
}

export {ShowMenuProvider, useShowMenu, useShowMenuDispatch};
