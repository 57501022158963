import {getAllGuestsByFirm} from '../users/usersActions';
import Guest from '../users/Guest';

export default async (firmUid, allMyCollaborators): Promise<Guest[]> => {
  const allMyGuests = await getAllGuestsByFirm(firmUid);

  return allMyGuests.filter(
    guest => allMyCollaborators.map(c => c.email).indexOf(guest.email) === -1,
  );
};
