import I18n from 'i18n-js';

import en from './en';
import fr from './fr';

const locale = navigator.language;

if (locale === 'en') {
  I18n.locale = 'en';
} else {
  I18n.locale = 'fr';
}

// I18n.locale = 'fr';

I18n.fallbacks = true;
I18n.missingBehaviour = 'guess';
I18n.translations = {
  en,
  fr,
};

export default I18n;
