import React, {useEffect} from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';

import {useUser} from '../auth/userContext';
import Menu, {MENU_WIDTH_WITH_PX} from './Menu';
import routes from './routes';
import AdminHome from '../superAdminApp/AdminHome';
import ReferentHome from '../referentApp/ReferentHome';
import Home from '../meetings/Home';
import MeetingPage from '../meetings/MeetingPage';
import MeetingEvaluation from '../meetings/MeetingEvaluation';
import Survey from '../meetings/Survey';
import SurveyResults from '../meetings/SurveyResults';
import {CurrentMeetingProvider} from '../meetings/currentMeetingContext';
import {useShowMenu, useShowMenuDispatch} from './showMenuContext';
import AnonymousNameForm from '../auth/AnonymousNameForm';

export default () => {
  const user = useUser();
  const showMenu = useShowMenu();
  const dispatchShowMenu = useShowMenuDispatch();

  useEffect(() => {
    dispatchShowMenu(true);
  }, []);

  const PageContainer = styled.div`
    margin-left: ${showMenu ? MENU_WIDTH_WITH_PX : 0};
  `;

  const SuperAdminApp = () => {
    console.log('Super admin App');

    return (
      <Route path={routes.home} exact>
        <AdminHome />
      </Route>
    );
  };

  console.log('user', user);
  console.log('user.firstName', user.firstName);

  const UserApp = () => {
    if (user.isReferent) {
      console.log('Referent App');

      return (
        <Route path={routes.home} exact>
          <ReferentHome />
        </Route>
      );
    }

    if (!user.firstName || user.firstName === 'guest') {
      return <AnonymousNameForm />;
    }

    console.log('Normal user App');

    return (
      <>
        <Route path={routes.home} exact>
          <Home />
        </Route>

        <Route path={routes.meetingPageMatcher} exact>
          <MeetingPage />
        </Route>

        <Route path={routes.evaluationEvaluationMatcher} exact>
          <MeetingEvaluation />
        </Route>

        <Route path={routes.survey} exact>
          <Survey />
        </Route>

        <Route path={routes.surveyResults} exact>
          <SurveyResults />
        </Route>
      </>
    );
  };

  return (
    <Router>
      <Menu />

      <PageContainer>
        <CurrentMeetingProvider>
          <Switch>
            {user?.isSuperAdmin ? <SuperAdminApp /> : <UserApp />}
          </Switch>
        </CurrentMeetingProvider>
      </PageContainer>
    </Router>
  );
};
