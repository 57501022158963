import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Face} from '@material-ui/icons';
import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';

import I18n from '../translations/i18n';
import colors from '../theme/colors';

export default ({
  guest,
  numberOfAvailableLicences,
  setGuestToToggleFacilitator,
}) => {
  const {addToast} = useToasts();

  const [touched, setTouched] = useState(false);

  const className = touched ? 'touchableOpacityTouched' : 'touchableOpacity';

  const handleMouseUp = () => {
    setTimeout(() => {
      setTouched(false);
    }, 1);
  };

  const toggleTouched = () => {
    setTouched(t => !t);
  };

  const onCrownClick = guest => {
    const shouldBeFacilitatorNow = !guest.isFacilitator;

    if (shouldBeFacilitatorNow && numberOfAvailableLicences <= 0) {
      addToast(I18n.t('noLicenceAvailableLeft'), {appearance: 'error'});
      return;
    }
    setGuestToToggleFacilitator(guest);
  };

  return (
    <ListItem
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      key={guest.uid}>
      <svg
        onMouseUp={handleMouseUp}
        className={className}
        onMouseDown={toggleTouched}
        onClick={() => onCrownClick(guest)}
        style={{
          width: 26,
          height: 26,
          color: guest.isFacilitator ? colors.primary : colors.gray2,
          marginRight: 26,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512">
        <path
          fill="currentColor"
          d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
        />
      </svg>

      <ListItemIcon>
        <span
          style={{
            marginLeft: 8,
            marginBottom: -4,
            alignSelf: 'center',
          }}>
          <Face />
        </span>
      </ListItemIcon>

      <ListItemText primary={guest.email} />
    </ListItem>
  );
};
