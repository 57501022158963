import React from 'react';
import styled from 'styled-components';
import I18n from '../translations/i18n';
import {withErrorBoundary} from 'react-error-boundary';

import quantile from './quantile';
import colors from '../theme/colors';
import useWindowSize from '../util/useWindowSize';
import {errorBoundaryHandler} from '../util/errorBoundaryHandler';
import {MENU_WIDTH} from '../navigation/Menu';

export const barHeight = 60;
export const deviceWidthRatio = 0.76;

const SurveyResultBar = ({values}) => {
  const windowSize = useWindowSize();

  if (!values || !values.length || !values.filter(v => v).length) {
    return (
      <NoVoteForThisCursor>{I18n.t('noVoteForThisCursor')}</NoVoteForThisCursor>
    );
  }

  const offset = 13;

  const firstQuartile = quantile(values, 1 / 4);
  const median = quantile(values, 1 / 2);
  const lastQuartile = quantile(values, 3 / 4);

  const firstCursor =
    firstQuartile * (windowSize.width * deviceWidthRatio) +
    barHeight / 2 +
    offset;
  const secondCursor =
    median * (windowSize.width * deviceWidthRatio) + barHeight / 2 + offset;
  const thirdCursor =
    lastQuartile * (windowSize.width * deviceWidthRatio) +
    barHeight / 2 +
    offset;
  const cursors = [firstCursor, secondCursor, thirdCursor].filter(n => n);

  const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: row;
    width: ${windowSize.width - MENU_WIDTH + 'px'};
  `;

  const RectangleBar = styled.div`
    background-color: ${colors.primaryDarkVariation};
    height: ${barHeight + 'px'};
    width: ${windowSize.width * deviceWidthRatio - MENU_WIDTH + 'px'};
    z-index: 1;
    position: absolute;
  `;

  const BarDemiCircleLeft = styled.div`
    height: ${barHeight + 'px'};
    width: ${barHeight / 2 + 'px'};
    border-top-left-radius: ${barHeight + 'px'};
    border-bottom-left-radius: ${barHeight + 'px'};
    background-color: ${colors.primaryDarkVariation};
    z-index: 0;
    position: absolute;
    left: ${((1 - deviceWidthRatio) / 2) * windowSize.width -
    barHeight / 2 +
    MENU_WIDTH +
    'px'};
  `;

  const BarDemiCircleRight = styled.div`
    height: ${barHeight + 'px'};
    width: ${barHeight / 2 + 'px'};
    background-color: ${colors.primaryDarkVariation};
    border-top-right-radius: ${barHeight + 'px'};
    border-bottom-right-radius: ${barHeight + 'px'};
    z-index: 1;
    position: absolute;
    left: ${windowSize.width -
    ((1 - deviceWidthRatio) / 2) * windowSize.width +
    'px'};
  `;

  const SLine = ({x, i}) => {
    if (Number.isNaN(x)) return <></>;

    return i === 1 ? (
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={barHeight}
        style={{
          stroke: colors.secondary,
          strokeWidth: 5,
        }}
      />
    ) : (
      <line
        strokeDasharray="1, 5"
        strokeLinecap="round"
        x1={x}
        y1={0}
        x2={x}
        y2={barHeight}
        style={{
          stroke: 'white',
          strokeWidth: 3,
        }}
      />
    );
  };

  return (
    <Container>
      <div
        style={{
          width: windowSize.width,
          zIndex: 999,
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <BarDemiCircleLeft />
        <RectangleBar />

        <svg
          style={{
            height: barHeight,
            width: windowSize.width * deviceWidthRatio + barHeight,
            zIndex: 9,
          }}>
          {cursors.map((x, i) => {
            return (
              <React.Fragment key={i}>
                <SLine x={x} i={i} />
                <circle
                  cx={x}
                  cy={barHeight / 2}
                  r={barHeight / 4}
                  fill="white"
                  style={{
                    zIndex: 10,
                    stroke: i !== 1 ? colors.primary : colors.secondary,
                    strokeWidth: 6,
                  }}
                />
                {i === 2 && Math.round(lastQuartile * 10) === 10 ? (
                  <text
                    x={x - 7.5}
                    y={barHeight / 2 + 5}
                    stroke={i === 1 ? colors.secondary : colors.primary}
                    strokeWidth="1.5"
                    style={{
                      fontSize: 14,
                    }}>
                    10
                  </text>
                ) : (
                  <text
                    x={x - 3.5}
                    y={barHeight / 2 + 5}
                    stroke={i === 1 ? colors.secondary : colors.primary}
                    strokeWidth="1.5"
                    style={{
                      fontSize: 14,
                    }}>
                    {i === 0 && Math.round(firstQuartile * 10)}
                    {i === 1 && Math.round(median * 10)}
                    {i === 2 && Math.round(lastQuartile * 10)}
                  </text>
                )}
              </React.Fragment>
            );
          })}
        </svg>
      </div>

      <BarDemiCircleRight />
    </Container>
  );
};

const ErrorBoundaryFallback = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}>
      <span style={{fontSize: 16, color: colors.gray4}}>
        Erreur, impossible d'afficher le résultat de ce curseur
      </span>
    </div>
  );
};

export default withErrorBoundary(SurveyResultBar, {
  fallback: ErrorBoundaryFallback,
  errorBoundaryHandler,
});

const NoVoteForThisCursor = styled.span`
  color: white;
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
`;
