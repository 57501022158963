import React from 'react';
import styled from 'styled-components';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import firebase from 'firebase';
import {Link} from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import colors from '../theme/colors';
import {useLocation} from 'react-router-dom';

import routes from './routes';
import {Avatar} from '@material-ui/core';
import {useUser} from '../auth/userContext';
import {useHistory} from 'react-router-dom';

export const MENU_WIDTH = 60;
export const MENU_WIDTH_WITH_PX = '60px';

export default () => {
  const history = useHistory();
  const location = useLocation();
  const me = useUser();

  const logout = async () => {
    try {
      firebase.auth().signOut();
      return history.push(routes.home);
    } catch (e) {}
  };

  const shouldBeWhite = () => {
    if (!location?.pathname) return false;

    return (
      location.pathname.indexOf(routes.survey) > -1 ||
      location.pathname.indexOf(routes.surveyResults) > -1
    );
  };

  const Menu = styled.div`
    width: ${MENU_WIDTH_WITH_PX};
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: ${shouldBeWhite() ? colors.primary : colors.primary};
    border-right: ${shouldBeWhite() ? colors.gray2 + ' solid' : undefined};
    border-width: 0.9px;
  `;

  if (location.pathname.indexOf(routes.meetingEvaluation) > -1) {
    return <></>;
  }

  return (
    <Menu>
      <MenuContent>
        {/*<TopIconsLink to={routes.accountSettings}>*/}
        {me?.photoURL && <Avatar alt="Me" src={me.photoURL} />}
        {/*    <Settings fontSize="large" />*/}
        {/*</TopIconsLink>*/}
      </MenuContent>

      <LogoutContainer onClick={logout}>
        <ExitToAppIcon
          fontSize="large"
          style={{color: shouldBeWhite() ? 'white' : 'white'}}
        />
      </LogoutContainer>
    </Menu>
  );
};

const TopIconsLink = styled(Link)`
  color: white;
`;

const Settings = styled(SettingsIcon)`
  margin-top: 20px;
`;

const MenuContent = styled.div`
  height: 92%;
  width: ${MENU_WIDTH_WITH_PX};
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26px;
`;

const LogoutContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: white;
  cursor: pointer;
`;
