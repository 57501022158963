import {getFirmWhereIAmReferent} from '../firms/firmsActions';
import {updateUser} from '../users/usersActions';

export default async user => {
  if (!user?.email) return false;

  const firmWhereIAmReferent = await getFirmWhereIAmReferent(user.email);

  if (firmWhereIAmReferent) {
    await updateUser(user.uid, {firmUid: firmWhereIAmReferent.uid});
    return firmWhereIAmReferent.uid;
  } else {
    return false;
  }
};
