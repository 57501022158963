import React, {useEffect, useState} from 'react';
import {List, ListSubheader} from '@material-ui/core';
import styled from 'styled-components';

import FullPageSpinner from '../util/FullPageSpinner';
import User from '../users/User';
import I18n from '../translations/i18n';
import {useUser} from '../auth/userContext';
import getAllMyCollaborators from './getAllMyCollaborators';
import Firm from '../firms/Firm';
import {getMyFirm} from '../firms/firmsActions';
import getMyGuestCollaborators from './getMyGuestCollaborators';
import UserListItem from './UserListItem';
import AddUsersCard from './AddUsers';
import PieChartCard from './PieChartCard';
import DeleteUserModal from './DeleteUserModal';
import IsUserFacilitatorModal from './IsUserFacilitatorModal';
import GuestListItem from './GuestListItem';
import IsGuestFacilitatorModal from './IsGuestFacilitatorModal';

export default () => {
  const me = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [firm, setFirm] = useState<Firm | undefined>();

  const [users, setUsers] = useState<User[]>([]);
  const [guests, setGuests] = useState<any[]>([]);

  const [userToToggleFacilitator, setUserToToggleFacilitator] = useState<
    User | undefined
  >();
  const [guestToToggleFacilitator, setGuestToToggleFacilitator] = useState<
    any
  >();
  const [numberOfAttributedLicences, setNumberOfAttributedLicences] = useState(
    0,
  );
  const [numberOfAvailableLicences, setNumberOfAvailableLicences] = useState(0);

  async function loadPage() {
    setIsLoading(true);
    const [allMyCollaboratorsRegistered, myFirm] = await Promise.all([
      getAllMyCollaborators(me),
      getMyFirm(me.firmUid),
    ]);
    setUsers(allMyCollaboratorsRegistered);
    setFirm(myFirm);
    setGuests(
      await getMyGuestCollaborators(me.firmUid, allMyCollaboratorsRegistered),
    );
    setIsLoading(false);
  }

  useEffect(() => {
    loadPage();
  }, [me]);

  useEffect(() => {
    setNumberOfAttributedLicences(firm?.numberOfAttributedLicences || 0);
    setNumberOfAvailableLicences(
      (firm?.numberOfLicences || 0) - (firm?.numberOfAttributedLicences || 0),
    );
  }, [firm]);

  if (isLoading) return <FullPageSpinner />;

  return (
    <>
      <DeleteUserModal users={users} setUsers={setUsers} />
      <IsUserFacilitatorModal
        userToToggleFacilitator={userToToggleFacilitator}
        setUserToToggleFacilitator={setUserToToggleFacilitator}
        users={users}
        setUsers={setUsers}
        setNumberOfAttributedLicences={setNumberOfAttributedLicences}
        setNumberOfAvailableLicences={setNumberOfAvailableLicences}
      />

      <IsGuestFacilitatorModal
        guestToToggleFacilitator={guestToToggleFacilitator}
        setGuestToToggleFacilitator={setGuestToToggleFacilitator}
        guests={guests}
        setGuests={setGuests}
        setNumberOfAttributedLicences={setNumberOfAttributedLicences}
        setNumberOfAvailableLicences={setNumberOfAvailableLicences}
      />

      <div style={{textAlign: 'center', marginBottom: 60}}>
        <h1>{firm?.name}</h1>
      </div>

      <Cards>
        <PieChartCard
          numberOfAttributedLicences={numberOfAttributedLicences}
          numberOfAvailableLicences={numberOfAvailableLicences}
        />
        <AddUsersCard reloadPage={loadPage} setIsLoading={setIsLoading} />
      </Cards>

      <List dense style={{maxWidth: 500, marginTop: 30, marginLeft: 50}}>
        {users.length ? (
          <ListSubheader>{I18n.t('registeredUsers')}</ListSubheader>
        ) : (
          <></>
        )}
        {users.map(user => {
          return (
            <UserListItem
              user={user}
              numberOfAvailableLicences={numberOfAvailableLicences}
              setUserToToggleFacilitator={setUserToToggleFacilitator}
              key={user.uid}
            />
          );
        })}

        {guests.length ? (
          <ListSubheader>{I18n.t('guestsUsers')}</ListSubheader>
        ) : (
          <></>
        )}
        {guests.map(guest => {
          return (
            <GuestListItem
              guest={guest}
              numberOfAvailableLicences={numberOfAvailableLicences}
              setGuestToToggleFacilitator={setGuestToToggleFacilitator}
              key={guest.email}
            />
          );
        })}
      </List>
    </>
  );
};

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
`;
