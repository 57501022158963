import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import I18n from 'i18n-js';
import _ from 'lodash';
import styled from 'styled-components';
import {withErrorBoundary} from 'react-error-boundary';
import {useToasts} from 'react-toast-notifications';
import firebase from 'firebase';
import moment from 'moment';

import {getMeeting, updateMeeting} from './meetingActions';
import {
  useCurrentMeeting,
  useCurrentMeetingDispatch,
} from './currentMeetingContext';
import {useUser} from '../auth/userContext';
import MeetingStatusEnum from './MeetingStatusEnum';
import AttendeesStatusEnum from './AttendeeStatusEnum';
import {meetings} from '../firebase/firestoreCollections';
import FullPageSpinner from '../util/FullPageSpinner';
import colors from '../theme/colors';
import {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
} from '../util/errorBoundaryHandler';
import MemoizedSlider from './MemoizedSlider';
import routes from '../navigation/routes';
import MeetingPause from './MeetingPause';
import Inactive from './Inactive';
import soundWav from '../assets/sound.wav';

const MeetingPage = () => {
  const {addToast} = useToasts();

  const dispatchCurrentMeeting = useCurrentMeetingDispatch();
  const meeting = useCurrentMeeting();

  const history = useHistory();

  const me = useUser();

  const {uid: meetingUid} = useParams();

  useEffect(() => {
    dispatchCurrentMeeting('reset');
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [sliderValues, setSliderValues] = useState({});
  const isFacilitatorOfThisMeeting = meeting.facilitatorUid === me.uid;

  const sound = new Audio(soundWav);

  const setSliders = newMeeting => {
    const cursors = newMeeting?.dialogTable?.cursors;
    if (!cursors) return;

    setSliderValues(
      Object.assign(
        // @ts-ignore
        ...Object.entries(cursors)
          .sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
          .map(m => {
            const defaultEntry = {value: 0, firstName: '', lastName: ''};
            // @ts-ignore
            const lastEntry = m[1][m[1].length - 1]
              ? // @ts-ignore
                m[1][m[1].length - 1]
              : defaultEntry;
            return {
              [m[0]]: lastEntry,
            };
          }),
      ),
    );

    sound.play();
  };

  useEffect(() => {
    async function initData() {
      const firestoreMeeting = await getMeeting(meetingUid);
      dispatchCurrentMeeting(firestoreMeeting);
      setSliders(firestoreMeeting);
      setIsLoading(false);
    }
    initData();
  }, [meetingUid]);

  useEffect(() => {
    const ref = firebase
      .firestore()
      .collection(meetings)
      .where('uid', '==', meetingUid);

    return ref.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const newData = change.doc.data();

        dispatchCurrentMeeting(newData);

        if (newData.status === MeetingStatusEnum.terminated) {
          if (isFacilitatorOfThisMeeting) {
            return history.push(routes.home);
          } else {
            return history.push(routes.meetingEvaluation + meetingUid);
          }
        } else if (newData.status === MeetingStatusEnum.survey) {
          return history.push(routes.survey);
        }

        setSliders(newData);
      });
    });
  }, [meetingUid]);

  const setSliderValue = async (label, value, diff) => {
    let newValue;
    if (diff) {
      if (sliderValues[label].value + diff > 1) {
        newValue = 1;
      } else if (sliderValues[label].value + diff < 0) {
        newValue = 0;
      } else {
        newValue = sliderValues[label].value + diff;
      }
    } else {
      newValue = value;
    }

    const formattedUser = {
      firstName: me.firstName,
      lastName: me.lastName,
      photoURL: me.photoURL,
      userUid: me.uid,
    };

    const newEntry = {
      value: newValue / 100,
      date: new Date(),
      ...formattedUser,
    };

    setSliderValues(s => ({...s, [label]: newEntry}));

    try {
      const dialogTable = meeting.dialogTable;
      const cursors = dialogTable.cursors;

      await updateMeeting(meeting.uid, {
        ...meeting,
        dialogTable: {
          ...dialogTable,
          cursors: {
            ...cursors,
            [label]: cursors[label].concat(newEntry),
          },
        },
      });

      addToast(I18n.t('saved'), {appearance: 'success'});
    } catch (e) {
      console.log('e', e);

      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  const amIInactive = () => {
    return (
      meeting?.attendees?.find(a => a.uid === me.uid)?.status ===
      AttendeesStatusEnum.inactive
    );
  };

  if (meeting.status === MeetingStatusEnum.paused) {
    return <MeetingPause />;
  }

  if (amIInactive() && !isFacilitatorOfThisMeeting) {
    return <Inactive />;
  }

  return (
    <ScrollViewContainer>
      <StyledHeader>
        <Title>{meeting.name}</Title>
      </StyledHeader>

      <div>
        <SHr />
      </div>

      {meeting?.dialogTable?.cursors &&
        Object.entries(meeting.dialogTable.cursors)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(cursor => {
            // @ts-ignore
            return (
              <>
                <Item key={cursor[0] + 'view'}>
                  <SliderAndLabel>
                    <LabelAndInfo>
                      <CursorLabel>
                        {_.upperFirst(I18n.t(cursor[0]))}
                      </CursorLabel>
                    </LabelAndInfo>

                    <MemoizedSlider
                      key={cursor[0] + 'slider'}
                      entry={sliderValues[cursor[0]]}
                      setSliderValue={setSliderValue}
                      label={cursor[0]}
                    />
                  </SliderAndLabel>
                </Item>

                <div>
                  <SHr />
                </div>
              </>
            );
          })}
    </ScrollViewContainer>
  );
};

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 10px;
`;

const Title = styled.span`
  color: black;
  font-size: ${window.innerWidth > 800 ? '42px' : '28px'};
  font-family: Colonel, sans-serif;
`;

const ScrollViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${window.height};
`;

const LabelAndInfo = styled.div`
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 12px;
`;

const CursorLabel = styled.span`
  font-size: ${window.innerWidth > 800 ? '32px' : '24px'};
  line-height: 0;
  color: ${colors.primary};
  font-family: Colonel, sans-serif;
  font-weight: bold;
  padding-left: 10px;
`;

const Item = styled.div`
  min-height: 150px;
  padding-left: 10px;
  padding-right: 10px;
`;

const SliderAndLabel = styled.div`
  z-index: 99;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;

export default withErrorBoundary(MeetingPage, {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
});
