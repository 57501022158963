import {Button, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useToasts} from 'react-toast-notifications';
import {useHistory} from 'react-router-dom';

import I18n from '../translations/i18n';
import {getMeetingByCode, updateMeeting} from './meetingActions';
import MeetingStatusEnum from './MeetingStatusEnum';
import AttendeesStatusEnum from './AttendeeStatusEnum';
import {useUser} from '../auth/userContext';
import routes from '../navigation/routes';

export default () => {
  const me = useUser();
  const history = useHistory();

  const {addToast} = useToasts();

  const {watch, register} = useForm();

  const meetingCode = watch('meetingCode');

  const joinWithCode = async () => {
    const maybeMeeting = await getMeetingByCode(meetingCode);

    if (maybeMeeting) {
      if (maybeMeeting.status === MeetingStatusEnum.terminated) {
        return addToast(I18n.t('meetingIsTerminated'), {appearance: 'error'});
      }

      if (maybeMeeting.status === MeetingStatusEnum.notStarted) {
        return addToast(I18n.t('meetingHasNotStartedYet'), {
          appearance: 'error',
        });
      }

      await updateMeeting(maybeMeeting.uid, {
        attendees: [
          ...maybeMeeting.attendees,
          {...me, status: AttendeesStatusEnum.active},
        ],
        attendeesEmail: [
          ...(maybeMeeting.attendeesEmail || []),
          me.email || 'noEmailAsGuestUser',
        ],
      });

      return history.push(routes.meetingPage + maybeMeeting.uid);
    } else {
      return addToast(I18n.t('noMeetingFoundWithThisCode'), {
        appearance: 'error',
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: 60,
        flexWrap: 'wrap',
      }}>
      <TextField
        inputRef={register}
        name="meetingCode"
        autoCapitalize="none"
        label={I18n.t('meetingCode')}
        style={{marginTop: 10, maxWidth: '60%'}}
      />
      <Button
        onClick={joinWithCode}
        type="submit"
        color="primary"
        disabled={!meetingCode}
        style={{marginLeft: 20, marginTop: 10}}>
        {I18n.t('join')}
      </Button>
    </div>
  );
};
