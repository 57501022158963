import React from 'react';

export default ({onClick, style}) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16">
      <g id="evaluation_x5F_2">
        <g id="redessin-81" transform="translate(-7 192)">
          <path
            fill="currentColor"
            id="Trac&#xE9;_1936"
            d="M15-192l-2.472,5.267L7-185.889l4,4.1L10.056-176L15-178.733L19.944-176L19-181.789l4-4.1
  l-5.528-0.844L15-192z"
          />
        </g>
      </g>
      <g id="Calque_1"></g>
    </svg>
  );
};
