import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import firebase from 'firebase';
import styled from 'styled-components';

import MeetingStatusEnum from './MeetingStatusEnum';
import routes from '../navigation/routes';
import SurveyResultBars from './SurveyResultBars';
import {
  useCurrentMeeting,
  useCurrentMeetingDispatch,
} from './currentMeetingContext';
import {meetings} from '../firebase/firestoreCollections';
import colors from '../theme/colors';
import I18n from '../translations/i18n';
import {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
} from '../util/errorBoundaryHandler';
import FullPageSpinner from '../util/FullPageSpinner';
import useWindowSize from '../util/useWindowSize';
import {barHeight, deviceWidthRatio} from './SurveyResultBar';

const SurveyResults = () => {
  const windowSize = useWindowSize();
  const history = useHistory();

  const meeting = useCurrentMeeting();
  const dispatchCurrentMeeting = useCurrentMeetingDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [surveyResults, setSurveyResults] = useState([]);

  useEffect(() => {
    const ref = firebase
      .firestore()
      .collection(meetings)
      .where('uid', '==', meeting.uid);

    setIsLoading(false);

    return ref.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const newData = change.doc.data();

        if (newData.status === MeetingStatusEnum.running) {
          return history.push(routes.meetingPage + meeting.uid);
        }

        setSurveyResults(
          newData.surveys
            .filter(s => s.surveyId === meeting.surveyId)
            .map(s => s.sliderValues),
        );

        dispatchCurrentMeeting(newData);
      });
    });
  }, [meeting.uid, meeting.surveyId]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  const HeaderContainer = styled.div`
    width: ${windowSize.width};
    background-color: white;
  `;

  const Header = styled.div`
    position: absolute;
    left: ${((1 - deviceWidthRatio) / 2) * windowSize.width -
    barHeight / 2 +
    'px'};
    right: ${((1 - deviceWidthRatio) / 2) * windowSize.width -
    barHeight / 2 +
    'px'};
    display: flex;
    flex-direction: column;
  `;

  const HeaderFirstLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-left: 48px;
  `;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: windowSize.height,
      }}>
      <HeaderContainer>
        <Header>
          <HeaderFirstLine>
            {windowSize.width >= 800 && (
              <STitle>{I18n.t('surveyResults')}</STitle>
            )}
            {windowSize.width < 800 && <STitle>{I18n.t('results')}</STitle>}

            <span style={{fontWeight: 'bold'}}>
              <span style={{color: colors.secondary}}>
                {meeting.attendees?.length}
              </span>{' '}
              {I18n.t('attendees')}
            </span>
          </HeaderFirstLine>
        </Header>

        <div style={{marginTop: 55}}>
          <SHr />

          <NietzscheText>{I18n.t('surveyResultsSubTitle')}</NietzscheText>
        </div>
      </HeaderContainer>

      <Container>
        <SurveyResultBars meeting={meeting} surveyResults={surveyResults} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  height: 100%;
  background-color: ${colors.primary};
`;

const STitle = styled.h2`
  font-family: Colonel, sans-serif;
`;

const NietzscheText = styled.div`
  color: ${colors.primary};
  font-family: Colonel, sans-serif;
  text-align: center;
  font-size: 18px;
  margin-bottom: 6px;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;

export default withErrorBoundary(SurveyResults, {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
});
