import React from 'react';
import styled from 'styled-components';
import I18n from '../translations/i18n';

import colors from '../theme/colors';
import anim from '../assets/METAGORA_metamorphose_claire.gif';
import {MENU_WIDTH} from '../navigation/Menu';

const MyCircle = ({style}) => {
  return <Circle style={{position: 'absolute', ...style}} />;
};

export default () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: '100%',
        height: window.innerHeight,
        backgroundImage: 'url(' + anim + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      <MyCircle style={{top: 10, left: MENU_WIDTH + 10}} />
      <MyCircle style={{top: 10, right: 10}} />

      <Text>{I18n.t('meetingIsPaused')}</Text>

      <MyCircle style={{bottom: 10, left: MENU_WIDTH + 10}} />
      <MyCircle style={{bottom: 10, right: 10}} />
    </div>
  );
};

const Text = styled.p`
  color: ${colors.primary};
  font-size: 52px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  display: block;
  font-family: Colonel, sans-serif;
`;

const Circle = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${colors.secondary};
  border-radius: 10px;
`;
