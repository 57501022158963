import React, {useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';

import routes from '../navigation/routes';
import {useUser} from '../auth/userContext';
import ImageIcon from './ImageIcon';
import play from '../assets/icons/play.png';
import outils from '../assets/icons/outils.png';
import MeetingStatusEnum from '../meetings/MeetingStatusEnum';
import getLocale from '../translations/getLocale';
import colors from '../theme/colors';
import I18n from '../translations/i18n';

export default ({meeting, isGray}) => {
  moment.locale(getLocale());

  const me = useUser();
  const isFacilitatorOfThisMeeting = meeting.facilitatorUid === me.uid;

  const history = useHistory();

  const [touched, setTouched] = useState(false);

  const className = touched ? 'touchableOpacityTouched' : 'touchableOpacity';

  const handleMouseUp = () => {
    setTimeout(() => {
      setTouched(false);
    }, 1);
  };

  const toggleTouched = () => {
    setTouched(t => !t);
  };

  const isNow =
    meeting.status === MeetingStatusEnum.running ||
    meeting.status === MeetingStatusEnum.survey ||
    meeting.status === MeetingStatusEnum.surveyDialogTableChoice ||
    meeting.status === MeetingStatusEnum.paused;

  const style = isNow
    ? {backgroundColor: colors.secondaryVariation}
    : isGray
    ? {backgroundColor: colors.gray1}
    : {borderColor: colors.gray2, borderWidth: 1};

  const onClick = () => {
    return history.push(routes.meetingPage + meeting.uid);
  };

  return (
    <MeetingBox onClick={onClick} style={style}>
      <MeetingName>{_.upperFirst(meeting.name)}</MeetingName>

      <StatusTextAndIcon>
        <span style={{fontSize: 18}}>
          {isNow
            ? I18n.t('running')
            : _.upperFirst(moment(meeting.date).fromNow())}
        </span>
        {isNow ? (
          <svg
            onMouseUp={handleMouseUp}
            className={className}
            onMouseDown={toggleTouched}
            onClick={onClick}
            style={{
              width: 50,
              height: 50,
              color: colors.secondary,
              alignSelf: 'center',
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <polygon
              fill="currentColor"
              points="7 5 7 11 8 11 8 6.74 17.05 12 8 17.26 8 13 7 13 7 19 19.03 12 7 5"
            />
          </svg>
        ) : isFacilitatorOfThisMeeting ? (
          <SIcon source={outils} size={22} />
        ) : (
          <></>
        )}
      </StatusTextAndIcon>
    </MeetingBox>
  );
};

const MeetingBox = styled.div`
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
`;

const MeetingName = styled.span`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
`;

const StatusTextAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SIcon = styled(ImageIcon)`
  align-self: center;
`;
