import {useEffect, useState} from 'react';

const _second = 1000;
const _minute = _second * 60;

export default (end, startTimer) => {
  const [distance, setRemainingSecs] = useState(end - new Date());

  useEffect(() => {
    if (!startTimer) {
      return;
    }
    const interval = setInterval(() => {
      // if (!distance || distance > 0) {
      const now = new Date();
      setRemainingSecs(end - now);
      // }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTimer, distance, end]);

  const remainingSecs = Math.floor((distance % _minute) / _second);
  const isOver = distance <= 0;

  return {remainingSecs, isOver};
};
