import client from '../util/fetch';
import {ADD_USERS_TO_FIRM} from '../config/cloudFunctionsUrls';

export default (usersEmail: string[], firmUid: string) => {
  if (!usersEmail?.length || !firmUid) return;

  // @ts-ignore
  return client(ADD_USERS_TO_FIRM, {
    body: {firmUid, usersEmail},
  });
};
