import React, {createContext, useContext, useState} from 'react';

export const CurrentMeetingContext = createContext({});
const CurrentMeetingDispatchContext = createContext({});

function CurrentMeetingProvider({children, initialState = {}}) {
  const [newCurrentMeeting, setCurrentMeetingDetails] = useState(initialState);

  const setCurrentMeeting = newData => {
    if (newData === 'reset') {
      setCurrentMeetingDetails(initialState);
    } else {
      setCurrentMeetingDetails(m => ({...m, ...newData}));
    }
  };

  return (
    <CurrentMeetingContext.Provider value={newCurrentMeeting}>
      <CurrentMeetingDispatchContext.Provider value={setCurrentMeeting}>
        {children}
      </CurrentMeetingDispatchContext.Provider>
    </CurrentMeetingContext.Provider>
  );
}

function useCurrentMeeting() {
  const context = useContext(CurrentMeetingContext);

  if (!context) {
    throw new Error(
      'useCurrentMeetingState must be used within a CurrentMeetingProvider',
    );
  }

  return context;
}

function useCurrentMeetingDispatch() {
  const context = useContext(CurrentMeetingDispatchContext);

  if (!context) {
    throw new Error(
      'useCurrentMeetingDispatch must be used within a CurrentMeetingProvider',
    );
  }

  return context;
}

export {CurrentMeetingProvider, useCurrentMeeting, useCurrentMeetingDispatch};
