import React from 'react';
import {Card} from '@material-ui/core';
import {PieChart} from 'react-minimal-pie-chart';

import I18n from '../translations/i18n';
import colors from '../theme/colors';

export default ({numberOfAvailableLicences, numberOfAttributedLicences}) => {
  return (
    <Card
      style={{
        flex: 1,
        marginLeft: 50,
        marginRight: 25,
        height: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{flex: 1}}>
        <PieChart
          style={{height: 180}}
          data={[
            {
              title: I18n.t('attributedLicences'),
              value: numberOfAttributedLicences,
              color: colors.palette4,
            },
            {
              title: I18n.t('availableLicences'),
              value: numberOfAvailableLicences,
              color: colors.palette3,
            },
          ]}
        />
      </div>

      <div style={{flex: 1, flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: 'baseline'}}>
          <div
            style={{
              height: 15,
              width: 15,
              backgroundColor: colors.palette4,
              marginRight: 10,
            }}
          />
          <span style={{fontSize: 18}}>
            {I18n.t('attributedLicences')} {numberOfAttributedLicences}
          </span>
        </div>

        <div style={{display: 'flex', alignItems: 'baseline'}}>
          <div
            style={{
              height: 15,
              width: 15,
              backgroundColor: colors.palette3,
              marginTop: 20,
              marginRight: 10,
            }}
          />
          <span style={{fontSize: 18}}>
            {I18n.t('availableLicences')} {numberOfAvailableLicences}
          </span>
        </div>
      </div>
    </Card>
  );
};
