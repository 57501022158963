import React from 'react';

export default ({onClick, style}) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16">
      <g id="evaluation_x5F_1">
        <g id="redessin-57">
          <path
            stroke="white"
            id="Trac&#xE9;_1893"
            d="M16,6.111l-5.528-0.845L8,0L5.528,5.267L0,6.112l4,4.1L3.056,16L7,13.82v-1.143l-2.626,1.452
			l0.613-3.756l0.081-0.5L4.716,9.512L2.075,6.805l3.6-0.551l0.527-0.081L6.429,5.69L8,2.354l1.567,3.338l0.227,0.483l0.527,0.081
			l3.6,0.551L11.28,9.514l-0.352,0.361l0.081,0.5l0.613,3.756L9,12.677v1.143L12.944,16L12,10.211L16,6.111z"
          />
        </g>
      </g>
      <g id="Calque_1"></g>
    </svg>
  );
};
