import React, {useEffect, useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import FullPageSpinner from '../util/FullPageSpinner';
import UsersReferentAdministration from './UsersReferentAdministration';
import TabPanel from '../util/TabPanel';
import I18n from '../translations/i18n';

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    async function initData() {
      setIsLoading(false);
    }
    initData();
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <>
      {/*<Paper square>*/}
      {/*  <Tabs*/}
      {/*    value={tab}*/}
      {/*    indicatorColor="primary"*/}
      {/*    textColor="primary"*/}
      {/*    centered*/}
      {/*    onChange={handleTabChange}*/}
      {/*    aria-label="tabs">*/}
      {/*    <Tab label={I18n.t('firmManagement')} />*/}
      {/*    <Tab label={I18n.t('userManagement')} />*/}
      {/*  </Tabs>*/}
      {/*</Paper>*/}

      {/*<TabPanel value={tab} index={0}>*/}
      {/*  <Firms />*/}
      {/*</TabPanel>*/}

      {/*<TabPanel value={tab} index={1}>*/}
      {/*</TabPanel>*/}
      <UsersReferentAdministration />
    </>
  );
};
