export const LanguagesEnum = Object.freeze({english: 'en', french: 'fr'});

export const MomentLocalesEnum = Object.freeze({english: 'en', french: 'fr'});

export const momentLocaleFromLanguage = language => {
  if (language === LanguagesEnum.english) {
    return MomentLocalesEnum.english;
  }
  return MomentLocalesEnum.french;
};
