import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import firebase from 'firebase';
import styled from 'styled-components';
import _ from 'lodash';

import I18n from '../translations/i18n';
import {useCurrentMeeting} from './currentMeetingContext.js';
import {updateMeeting} from './meetingActions';
import MemoizedSlider from './MemoizedSlider';
import {useUser} from '../auth/userContext';
import colors from '../theme/colors';
import {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
} from '../util/errorBoundaryHandler';
import routes from '../navigation/routes';
import FullPageSpinner from '../util/FullPageSpinner';
import useWindowSize from '../util/useWindowSize';
import SurveyTimer from './SurveyTimer';

const Survey = () => {
  const windowSize = useWindowSize();
  const history = useHistory();

  const meeting = useCurrentMeeting();
  const me = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [sliderValues, setSliderValues] = useState({});

  useEffect(() => {
    async function goToResults() {
      const formattedUser = {
        firstName: me.firstName,
        lastName: me.lastName,
        photoURL: me.photoURL,
        userUid: me.uid,
      };

      await updateMeeting(meeting.uid, {
        surveys: firebase.firestore.FieldValue.arrayUnion({
          sliderValues,
          user: formattedUser,
          surveyId: meeting.surveyId,
          date: new Date(),
        }),
      });
      return history.push(routes.surveyResults);
    }

    const timer = setTimeout(
      () => goToResults(),
      returnSurveyEndTime() - new Date() + 4000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const returnSurveyEndTime = () => {
    return meeting.surveyEndTime instanceof Date
      ? meeting.surveyEndTime
      : meeting.surveyEndTime.toDate();
  };

  const initialValues = Object.assign(
    ...Object.entries(meeting.surveyDialogTable.cursors).map(m => {
      return {
        [m[0]]: {value: 0},
      };
    }),
  );

  useEffect(() => {
    setSliderValues(initialValues);
    setIsLoading(false);
  }, [meeting?.surveyDialogTable?.cursors]);

  const setSliderValue = async (label, value, diff) => {
    let newValue;
    if (diff) {
      if (sliderValues[label].value + diff > 1) {
        newValue = 1;
      } else if (sliderValues[label].value + diff < 0) {
        newValue = 0;
      } else {
        newValue = sliderValues[label].value + diff;
      }
    } else {
      newValue = value;
    }
    const newEntry = {
      value: newValue / 100,
    };

    setSliderValues(s => ({...s, [label]: newEntry}));
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: windowSize.height,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}>
        <STitle>{I18n.t('survey')}</STitle>

        <SurveyTimer endTime={returnSurveyEndTime(meeting.surveyEndTime)} />
      </div>

      <DividerMargins />
      <SHr />

      <NietzscheText>{I18n.t('surveySubTitle')}</NietzscheText>

      <Container>
        {Object.entries(meeting.surveyDialogTable.cursors).map(
          (cursor, i, arr) => {
            return (
              <div style={{}} key={cursor[0] + 'view'}>
                <SliderAndLabel>
                  <LabelAndInfo>
                    <CursorLabel>{_.upperFirst(I18n.t(cursor[0]))}</CursorLabel>
                  </LabelAndInfo>

                  <MemoizedSlider
                    key={cursor[0] + 'slider'}
                    entry={sliderValues[cursor[0]]}
                    setSliderValue={setSliderValue}
                    label={cursor[0]}
                    trackBackgroundColor={colors.primaryDarkVariation}
                    thumbColor={colors.secondary}
                    plusColor="white"
                  />
                </SliderAndLabel>

                {i !== arr.length - 1 && (
                  <DividerContainer>
                    <SHr />
                  </DividerContainer>
                )}
              </div>
            );
          },
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 100px 30px;
  background-color: ${colors.primary};
`;

const STitle = styled.span`
  margin-left: 20px;
  font-size: 32px;
  font-family: Colonel, sans-serif;
`;

const DividerContainer = styled.div`
  margin: 110px -40px 10px;
`;

const DividerMargins = styled.div`
  margin-top: -15px;
`;

const NietzscheText = styled.span`
  color: ${colors.primary};
  font-family: Colonel, sans-serif;
  text-align: center;
  padding-bottom: 6px;
  font-size: 18px;
`;

const SliderAndLabel = styled.div`
  z-index: 9;
`;

const LabelAndInfo = styled.div`
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const CursorLabel = styled.span`
  font-size: 32px;
  line-height: 22px;
  color: white;
  font-family: Colonel, sans-serif;
  font-weight: bold;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;

export default withErrorBoundary(Survey, {
  ErrorBoundaryFallback,
  errorBoundaryHandler,
});
