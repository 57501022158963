import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import UsersSuperAdministration from './UsersSuperAdministration';
import TabPanel from '../util/TabPanel';
import Firms from './Firms';
import I18n from '../translations/i18n';
import CursorInformationManagement from './CursorInformationManagement';
import SpecialCursorInformationManagement from './SpecialCursorInformationManagement';

export default () => {
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          centered
          onChange={handleTabChange}
          aria-label="tabs">
          <Tab label={I18n.t('firmManagement')} />
          <Tab label={I18n.t('userManagement')} />
          <Tab label={I18n.t('cursorInformationManagement')} />
          <Tab label={I18n.t('specialCursorInformationManagement')} />
        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0}>
        <Firms />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <UsersSuperAdministration />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <CursorInformationManagement />
      </TabPanel>

      <TabPanel value={tab} index={3}>
        <SpecialCursorInformationManagement />
      </TabPanel>
    </>
  );
};
