import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import routes from './routes';
import MobileAppConfidentiality from './MobileAppConfidentiality';
import {UserProvider} from '../auth/userContext';
import Login from '../auth/Login';
import Register from '../auth/Register';

export default () => {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route path={routes.home} exact>
            <Login />
          </Route>

          <Route path={routes.createAccount} exact>
            <Register />
          </Route>

          <Route path={routes.confidentiality} exact>
            <MobileAppConfidentiality />
          </Route>
        </Switch>
      </Router>
    </UserProvider>
  );
};
