import firebase from 'firebase';

import {
  cursors,
  meetings,
  meetingsEvaluations,
  specialCursors,
  tables,
  tablesMatching,
} from '../firebase/firestoreCollections';
import SpecialCursor from '../superAdminApp/SpecialCursor';

export const createMeeting = async meeting => {
  return firebase
    .firestore()
    .collection(meetings)
    .doc(meeting.uid)
    .set(meeting);
};

export const updateMeeting = async (uid, newData) => {
  return firebase.firestore().collection(meetings).doc(uid).update(newData);
};

export const addEvaluation = async (
  user,
  meetingUid,
  facilitatorUid,
  rating,
  comment,
  determinantCursor,
) => {
  const evaluationUid = user.uid + ':' + meetingUid;

  return firebase
    .firestore()
    .collection(meetingsEvaluations)
    .doc(evaluationUid)
    .set({
      user,
      meetingUid,
      facilitatorUid,
      rating,
      comment,
      determinantCursor,
      date: new Date(),
    });
};

export const getMeeting = uid => {
  return firebase
    .firestore()
    .collection(meetings)
    .doc(uid)
    .get()
    .then(meeting => meeting.data());
};

export const deleteMeeting = uid => {
  return firebase.firestore().collection(meetings).doc(uid).delete();
};

export const getAllMeetingsIHaveBeenInvited = async email => {
  const rawData = await firebase
    .firestore()
    .collection(meetings)
    .where('attendeesEmail', 'array-contains', email)
    .get();

  return rawData.docs.map(doc => ({
    ...doc.data(),
    date: doc.data().date.toDate(),
  }));
};

export const getAllMyFacilitatorMeetings = async facilitatorUid => {
  const rawData = await firebase
    .firestore()
    .collection(meetings)
    .where('facilitatorUid', '==', facilitatorUid)
    .get();

  return rawData.docs.map(doc => ({
    ...doc.data(),
    date: doc.data().date.toDate(),
  }));
};

export const getTables = () => {
  return firebase
    .firestore()
    .collection(tables)
    .get()
    .then(table => table.docs.map(doc => doc.data()));
};

export const getMatchingTable = (type, desiredEffect) => {
  return firebase
    .firestore()
    .collection(tablesMatching)
    .where('type', '==', type)
    .where('desiredEffect', '==', desiredEffect)
    .get()
    .then(table => table.docs.map(doc => doc.data())[0]);
};

export const addMyInfoToMeetingsWhereIAmGuest = async me => {
  const response = await firebase
    .firestore()
    .collection(meetings)
    .where('attendeesEmail', 'array-contains', me.email)
    .get();

  const batch = firebase.firestore().batch();

  response.docs.forEach(doc => {
    batch.update(doc.ref, {
      attendees: firebase.firestore.FieldValue.arrayUnion(me),
    });
  });

  return batch.commit();
};

export const getAllCursors = () => {
  return firebase
    .firestore()
    .collection(cursors)
    .get()
    .then(cursors => cursors.docs.map(cursor => cursor.data()));
};

export const updateCursor = (cursorKey, newData) => {
  return firebase
    .firestore()
    .collection(cursors)
    .doc(cursorKey)
    .update(newData);
};

export const getAllSpecialCursors = () => {
  return firebase
    .firestore()
    .collection(specialCursors)
    .get()
    .then(cursors => cursors.docs.map(cursor => cursor.data()));
};

export const createSpecialCursor = (cursor: SpecialCursor) => {
  return firebase
    .firestore()
    .collection(specialCursors)
    .doc(cursor.uid)
    .set(cursor);
};

export const updateSpecialCursor = (cursorKey, newData) => {
  return firebase
    .firestore()
    .collection(specialCursors)
    .doc(cursorKey)
    .update(newData);
};

export const deleteSpecialCursor = uid => {
  return firebase.firestore().collection(specialCursors).doc(uid).delete();
};

export const getMeetingByCode = code => {
  return firebase
    .firestore()
    .collection(meetings)
    .where('joinMeetingCode', '==', code)
    .get()
    .then(ms => ms.docs.map(doc => doc.data())[0]);
};
