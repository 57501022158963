import React from 'react';

import I18n from '../translations/i18n';
import colors from '../theme/colors';
import useTimer from './useTimer';

const SurveyTimer = ({endTime}) => {
  const {remainingSecs, isOver} = useTimer(
    //if isFacilitator, the date comes from the app otherwise from Firestore thus:
    endTime,
    true,
  );

  return (
    <span style={{fontWeight: 'bold'}}>
      {!isOver && (
        <span>
          {window.innerWidth >= 800 && I18n.t('remaining')}{' '}
          <span style={{color: colors.secondary}}>{remainingSecs}</span>{' '}
          {I18n.t('remainingSeconds')}
        </span>
      )}
    </span>
  );
};

export default SurveyTimer;
