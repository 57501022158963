import isDev from './isDev';

export const cloudFunctionsUrl = isDev
  ? 'http://localhost:5001/metagora-72833/us-central1/'
  : 'https://us-central1-metagora-72833.cloudfunctions.net/';

export const DELETE_USER_URL = (adminUid: string, userToDeleteUid: string) => {
  return (
    cloudFunctionsUrl +
    `deleteUser?adminUid=${adminUid}&userToDeleteUid=${userToDeleteUid}`
  );
};

export const UPDATE_IS_FACILITATOR = (
  adminUid: string,
  userUid: string,
  shouldBeFacilitatorNow: boolean,
) => {
  return (
    cloudFunctionsUrl +
    `updateIsFacilitator?adminUid=${adminUid}&userUid=${userUid}&shouldBeFacilitatorNow=${shouldBeFacilitatorNow}`
  );
};

export const UPDATE_GUEST_IS_FACILITATOR = (
  adminUid: string,
  guestEmail: string,
  shouldBeFacilitatorNow: boolean,
) => {
  return (
    cloudFunctionsUrl +
    `updateGuestIsFacilitator?adminUid=${adminUid}&guestEmail=${guestEmail}&shouldBeFacilitatorNow=${shouldBeFacilitatorNow}`
  );
};

export const SEND_INVITATION_MAIL = (
  recipientMail: string,
  locale: string,
  firmName?: string,
) => {
  return (
    cloudFunctionsUrl +
    `sendInvitationMail?recipientMail=${recipientMail}&firmName=${firmName}&locale=${locale}`
  );
};

export const ADD_USERS_TO_FIRM = cloudFunctionsUrl + `addUsersToFirm`;
