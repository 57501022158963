import firebase from 'firebase';

import {firms} from '../firebase/firestoreCollections';
import Firm from './Firm';

export const getMyFirm = async (
  firmUid?: string,
): Promise<Firm | undefined> => {
  if (!firmUid) return undefined;

  const data = await firebase
    .firestore()
    .collection(firms)
    .where('uid', '==', firmUid)
    .get();

  if (data.docs.length) {
    return data.docs[0].data() as Firm;
  } else {
    return undefined;
  }
};

export const getFirmWhereIAmReferent = async (
  userEmail: string,
): Promise<Firm | undefined> => {
  const data = await firebase
    .firestore()
    .collection(firms)
    .where('referentsEmail', 'array-contains', userEmail)
    .get();

  if (data.docs.length) {
    return data.docs[0].data() as Firm;
  } else {
    return undefined;
  }
};

export const getAllFirms = async (): Promise<Firm[]> => {
  const data = await firebase.firestore().collection(firms).get();

  return data.docs.map(d => {
    return {...d.data(), createdDate: d.data().createdDate.toDate()} as Firm;
  });
};

export const addFirm = async (firm: Firm) => {
  return await firebase.firestore().collection(firms).doc(firm.uid).set(firm);
};

export const updateFirm = async (
  firmUid: string | undefined,
  partialFirm: any,
) => {
  if (!firmUid) return;

  return firebase
    .firestore()
    .collection(firms)
    .doc(firmUid)
    .update(partialFirm);
};
