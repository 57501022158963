import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import firebase from 'firebase';
import styled from 'styled-components';

import {useUser} from '../auth/userContext';
import MeetingStatusEnum from '../meetings/MeetingStatusEnum';
import {meetings as meetingsCollection} from '../firebase/firestoreCollections';
import colors from '../theme/colors';
import FullPageSpinner from '../util/FullPageSpinner';
import Meeting from './Meeting';
import I18n from '../translations/i18n';
import {useShowMenu, useShowMenuDispatch} from '../navigation/showMenuContext';
import {MENU_WIDTH} from '../navigation/Menu';
import JoinMeetingByCode from './JoinMeetingByCode';

export default () => {
  const me = useUser();
  const showMenu = useShowMenu();
  const dispatchShowMenu = useShowMenuDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [meetings, setMeetings] = useState<any[]>([]);

  useEffect(() => {
    dispatchShowMenu(true);
  }, []);

  const ref = firebase
    .firestore()
    .collection(meetingsCollection)
    .where('status', '!=', MeetingStatusEnum.terminated)
    .where('attendeesEmail', 'array-contains', me.email || 'nonExistentEmail');

  useEffect(() => {
    async function initData() {
      if ((await ref.get()).docs.length === 0) {
        setIsLoading(false);
      }
    }
    initData();
  }, []);

  useEffect(() => {
    return ref.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const newMeeting = {
          ...change.doc.data(),
          date: change.doc.data().date.toDate(),
        };

        setMeetings(m => {
          const allMeetings: any[] = m.concat([newMeeting]);

          const currentMeetings = allMeetings
            .filter(meeting => {
              return (
                meeting.status === MeetingStatusEnum.running ||
                meeting.status === MeetingStatusEnum.paused ||
                meeting.status === MeetingStatusEnum.survey ||
                meeting.status === MeetingStatusEnum.surveyDialogTableChoice
              );
            })
            .sort((a, b) => a.date.getTime() - b.date.getTime());

          const upcomingMeetings = allMeetings
            .filter(meeting => {
              return meeting.status === MeetingStatusEnum.notStarted;
            })
            .sort((a, b) => a.date.getTime() - b.date.getTime());

          return _.uniqBy(currentMeetings.concat(upcomingMeetings), 'uid');
        });
        setIsLoading(false);
      });
    });
  }, [me.email]);

  return (
    <>
      <StyledHeader>
        <BlueText>Metagora</BlueText>
      </StyledHeader>

      {me.isFacilitator && (
        <NietzscheText>{I18n.t('noFacilitatorFeatures')}</NietzscheText>
      )}

      <SHr />

      <Container>
        {isLoading ? (
          <FullPageSpinner />
        ) : (
          <>
            <JoinMeetingByCode />

            {meetings &&
              meetings.map((meeting, i) => (
                <Meeting
                  meeting={meeting}
                  isGray={i % 2 === 0}
                  key={meeting.uid}
                />
              ))}
          </>
        )}
      </Container>
    </>
  );
};

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
`;

const BlueText = styled.h1`
  color: ${colors.primary};
  font-size: 42px;
  line-height: 32px;
  font-family: Colonel, sans-serif;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;

const Container = styled.div`
  flex: 1;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
`;

const NietzscheText = styled.div`
  color: ${colors.primary};
  font-family: Colonel, sans-serif;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 21px;
`;
