import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {Create} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {useForm} from 'react-hook-form';
import {useToasts} from 'react-toast-notifications';
import uuid from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  createSpecialCursor,
  deleteSpecialCursor,
  getAllCursors,
  getAllSpecialCursors,
  updateSpecialCursor,
} from '../meetings/meetingActions';
import I18n from '../translations/i18n';
import {getAllFacilitators} from '../users/usersActions';
import styled from 'styled-components';
import SpecialCursor from './SpecialCursor';
import colors from '../theme/colors';
import {StyledTableCell, StyledTableRow} from '../theme/tableTheme';

export default () => {
  const classes = useStyles();
  const {addToast} = useToasts();
  const {watch, register, reset} = useForm();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [cursorToUpdate, setCursorToUpdate] = useState<any>();
  const [allPersonalizedCursors, setAllPersonalizedCursors] = useState<
    SpecialCursor[]
  >([]);
  const [allCursors, setAllCursors] = useState<any[]>([]);
  const [allDomainNames, setAllDomainNames] = useState<any[]>([]);

  useEffect(() => {
    async function initData() {
      const [personalizedCursors, cursors] = await Promise.all([
        getAllSpecialCursors(),
        getAllCursors(),
      ]);
      // @ts-ignore
      setAllPersonalizedCursors(personalizedCursors);
      setAllCursors(cursors);

      setAllDomainNames([
        ...new Set(
          (await getAllFacilitators())
            .map(f => extractDomainName(f.email))
            .sort((a, b) => a.localeCompare(b)),
        ),
      ]);
    }
    initData();
  }, []);

  const closeModal = () => {
    reset();
    setIsCreateModalOpen(false);
  };

  const onClickUpdate = cursor => {
    setCursorToUpdate(cursor);
    setIsUpdateModalOpen(true);
  };

  const onClickCreate = () => {
    setIsCreateModalOpen(true);
  };

  const submitCursorUpdate = async () => {
    const frenchCursorInformation = watch('frenchCursorInformation');
    const englishCursorInformation = watch('englishCursorInformation');

    try {
      const information = {
        information: {
          en: englishCursorInformation,
          fr: frenchCursorInformation,
        },
      };
      const updatedCursor = {...cursorToUpdate, ...information};

      await updateSpecialCursor(cursorToUpdate?.uid, information);

      const newCursors = allPersonalizedCursors.map(cursor => {
        if (cursor.uid === cursorToUpdate?.uid) {
          return updatedCursor;
        }
        return cursor;
      });

      setAllPersonalizedCursors(newCursors);
      reset();
      addToast(I18n.t('cursorModified'), {appearance: 'success'});
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsUpdateModalOpen(false);
    }
  };

  const submitCursorCreate = async () => {
    const cursorKeyTranslated = watch('cursorKey');
    const domainName = watch('domainName');
    const frenchCursorInformation = watch('frenchCursorInformation');
    const englishCursorInformation = watch('englishCursorInformation');

    const cursorKey = allCursors.find(
      c => I18n.t(c.cursorKey) === cursorKeyTranslated,
    )?.cursorKey;

    try {
      const newCursor: SpecialCursor = {
        uid: uuid.v4(),
        cursorKey,
        domainName,
        information: {
          en: englishCursorInformation,
          fr: frenchCursorInformation,
        },
      };

      await createSpecialCursor(newCursor);

      const newCursors = allPersonalizedCursors.concat(newCursor);
      setAllPersonalizedCursors(newCursors);
      reset();
      addToast(I18n.t('cursorCreated'), {appearance: 'success'});
    } catch {
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      closeModal();
    }
  };

  const extractDomainName = email => {
    return email.split('@')[email.split('@').length - 1];
  };

  const deleteCursor = async uid => {
    try {
      await deleteSpecialCursor(uid);
      setAllPersonalizedCursors(c => c.filter(c => c.uid !== uid));
      setIsUpdateModalOpen(false);
      addToast(I18n.t('cursorDeleted'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    }
  };

  const CreateCursorModal = () => {
    return (
      <Dialog open={isCreateModalOpen} onClose={closeModal}>
        <DialogTitle>{I18n.t('cursorCreation')}</DialogTitle>

        <DialogContent>
          <form>
            <Autocomplete
              id="cursorKey"
              options={allCursors}
              getOptionLabel={option => I18n.t(option.cursorKey)}
              style={{width: 300, marginBottom: 20}}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="cursorKey"
                  label={I18n.t('cursor')}
                  variant="outlined"
                />
              )}
            />

            <Autocomplete
              id="domainName"
              options={allDomainNames}
              getOptionLabel={option => option}
              style={{width: 300}}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="domainName"
                  label={I18n.t('emailDomain')}
                  variant="outlined"
                />
              )}
            />

            {/*<TextField*/}
            {/*  inputRef={register}*/}
            {/*  name="domainName"*/}
            {/*  defaultValue={cursorToUpdate?.domainName}*/}
            {/*  margin="dense"*/}
            {/*  label={I18n.t('emailDomain')}*/}
            {/*  fullWidth*/}
            {/*/>*/}

            <TextField
              inputRef={register}
              name="frenchCursorInformation"
              defaultValue={cursorToUpdate?.information?.fr}
              margin="dense"
              label={I18n.t('frenchCursorInformation')}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="englishCursorInformation"
              defaultValue={cursorToUpdate?.information?.en}
              margin="dense"
              label={I18n.t('englishCursorInformation')}
              fullWidth
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal} color="primary">
            {I18n.t('cancel')}
          </Button>

          <Button onClick={submitCursorCreate} type="submit" color="primary">
            {I18n.t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UpdateCursorModal = () => {
    return (
      <Dialog
        open={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}>
        <DialogTitle>
          {I18n.t('updateTheCursor')}
          {cursorToUpdate?.uid && I18n.t(cursorToUpdate?.cursorKey)}
        </DialogTitle>

        <DialogContent>
          <form>
            <TextField
              inputRef={register}
              name="domainName"
              defaultValue={cursorToUpdate?.domainName}
              margin="dense"
              label={I18n.t('emailDomain')}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="frenchCursorInformation"
              defaultValue={cursorToUpdate?.information?.fr}
              margin="dense"
              label={I18n.t('frenchCursorInformation')}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="englishCursorInformation"
              defaultValue={cursorToUpdate?.information?.en}
              margin="dense"
              label={I18n.t('englishCursorInformation')}
              fullWidth
            />
          </form>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: 20,
            paddingLeft: 22,
          }}>
          <Button
            onClick={() => deleteCursor(cursorToUpdate.uid)}
            style={{backgroundColor: colors.error, color: 'white'}}>
            {I18n.t('delete')}
          </Button>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button onClick={() => setIsUpdateModalOpen(false)} color="primary">
              {I18n.t('cancel')}
            </Button>

            <Button onClick={submitCursorUpdate} type="submit" color="primary">
              {I18n.t('submit')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <MainContainer>
      <UpdateCursorModal />

      <CreateCursorModal />

      <SButton color="secondary" onClick={onClickCreate} variant="contained">
        <span style={{color: 'white', fontWeight: 'bold'}}>
          {I18n.t('newPersonalizedI')}
        </span>
      </SButton>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">
                {I18n.t('emailDomain')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('cursor')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('frenchCursorInformation')}
              </StyledTableCell>

              <StyledTableCell align="center">
                {I18n.t('englishCursorInformation')}
              </StyledTableCell>

              <StyledTableCell align="center">Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {allPersonalizedCursors.map(cursor => (
              <StyledTableRow key={cursor.uid}>
                <StyledTableCell align="center" component="th" scope="row">
                  {cursor.domainName}
                </StyledTableCell>

                <StyledTableCell align="center" component="th" scope="row">
                  {I18n.t(cursor.cursorKey)}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cursor.information?.fr}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cursor.information?.en}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Create onClick={() => onClickUpdate(cursor)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainContainer>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SButton = styled(Button)`
  margin-bottom: 20px;
  width: max-content;
`;
