const MeetingStatusEnum = Object.freeze({
  notStarted: 'notStarted',
  running: 'running',
  surveyDialogTableChoice: 'surveyDialogTableChoice',
  survey: 'survey',
  paused: 'paused',
  terminated: 'terminated',
});

export default MeetingStatusEnum;
