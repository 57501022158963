import {getAllUsers} from '../users/usersActions';
import isACustomEmailProvider from '../mails/isACustomEmailProvider';

export default async me => {
  const allUsers = await getAllUsers();

  const isMyEmailACustomEmailProvider = isACustomEmailProvider(me.email);

  return allUsers
    .filter(u => {
      const isUserInMyFirm =
        u.firmUid && me.firmUid && u.firmUid === me.firmUid;

      const hasUserSameCustomEmailProviderThanMe =
        isMyEmailACustomEmailProvider &&
        u.email.split('@').length > 0 &&
        u.email.split('@')[1].toLowerCase() ===
          me.email.split('@')[1].toLowerCase();

      return isUserInMyFirm || hasUserSameCustomEmailProviderThanMe;
    })
    .filter(u => u.uid !== me.uid);
};
