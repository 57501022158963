import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import SurveyResultBar, {barHeight, deviceWidthRatio} from './SurveyResultBar';
import colors from '../theme/colors';
import I18n from '../translations/i18n';
import useWindowSize from '../util/useWindowSize';
import {MENU_WIDTH} from '../navigation/Menu';

export default ({meeting, surveyResults}) => {
  const windowSize = useWindowSize();

  const CursorLabel = styled.div`
    font-family: Colonel, sans-serif;
    font-size: 26px;
    color: white;
    position: absolute;
    left: ${((1 - deviceWidthRatio) / 2) * windowSize.width -
    barHeight / 2 +
    MENU_WIDTH +
    'px'};
  `;

  return (
    <BarsContainer>
      {Object.entries(meeting.surveyDialogTable.cursors).map(cursor => {
        return (
          <SliderAndLabel key={cursor[0] + 'view'}>
            <CursorLabel>{_.upperFirst(I18n.t(cursor[0]))}</CursorLabel>

            <SurveyResultBar
              values={surveyResults.map(
                userSurvey => userSurvey[cursor[0]]?.value,
              )}
            />
            <SHr />
          </SliderAndLabel>
        );
      })}

      <Caption>
        <MedianLine>
          <CaptionText>{I18n.t('median')}</CaptionText>
        </MedianLine>

        <CaptionGroup>
          <svg width="4px" height="34px">
            <line
              x1="2"
              x2="2"
              y1="2"
              y2="42"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeDasharray="1, 6"
            />
          </svg>
          <CaptionText>{I18n.t('quartiles')}</CaptionText>
        </CaptionGroup>
      </Caption>
    </BarsContainer>
  );
};

const BarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SliderAndLabel = styled.div`
  z-index: 9;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
`;

const CaptionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.primary};
`;

const MedianLine = styled.div`
  height: 30px;
  border-left: solid;
  border-left-width: 8px;
  border-color: ${colors.secondary};
`;

const CaptionText = styled.span`
  color: white;
  padding-left: 10px;
  font-size: 20px;
`;

const SHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.gray2};
`;
