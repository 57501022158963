import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {useForm} from 'react-hook-form';
import {Button, CardContent, TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import {Face, Email, Lock} from '@material-ui/icons';

import {createUser} from '../users/usersActions';
import {addMyInfoToMeetingsWhereIAmGuest} from '../meetings/meetingActions';
import I18n from '../translations/i18n';
import isDev from '../config/isDev';
import useStyles from './useStyles';
import routes from '../navigation/routes';
import firebase from 'firebase';

export default () => {
  const classes = useStyles();

  const history = useHistory();

  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = isDev
    ? {
        firstName: 'simon',
        lastName: 'garnier',
        email: 'metagora@test.test',
        password: 'metagora@test.test',
      }
    : {firstName: '', lastName: '', email: '', password: ''};

  const {handleSubmit, register} = useForm({defaultValues});

  const onSubmit = async (data: any): Promise<void> => {
    setIsLoading(true);

    try {
      const info = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .catch(error => {
          switch (error.code) {
            case 'auth/email-already-in-use':
              addToast(I18n.t('alreadyUsedEmail'), {
                appearance: 'error',
              });
              break;

            case 'auth/invalid-email':
              addToast(I18n.t('invalidEmail'), {appearance: 'error'});

              break;

            case 'auth/weak-password':
              addToast(I18n.t('tooShortPassword'), {appearance: 'error'});

              break;

            default:
              addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
          }
        });

      if (info) {
        const user = {
          uid: info.user!.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        };

        await Promise.all([
          // auth().currentUser.updateProfile({
          //   displayName: firstName,
          // }),
          addMyInfoToMeetingsWhereIAmGuest(user),
          createUser(user),
        ]);
        history.push(routes.home);
      }
    } catch (e) {
      console.log('e', e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Card className={classes.loginContainer}>
        <Card className={classes.header} elevation={1} square>
          <p className={classes.headerTitle}>Metagora</p>
        </Card>

        <CardContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.registerCardContent}>
            {/*<div>*/}
            <div className={classes.cardRow}>
              <Face className={classes.icon} />
              <TextField
                name="firstName"
                inputRef={register({required: I18n.t('firstNameIsRequired')})}
                placeholder="Aron"
                label={I18n.t('firstName')}
                autoCapitalize="words"
                fullWidth
                autoFocus
                required
              />
            </div>
            <div className={classes.cardRow}>
              <Face className={classes.icon} />
              <TextField
                name="lastName"
                inputRef={register({required: I18n.t('lastNameIsRequired')})}
                placeholder="Smith"
                label={I18n.t('lastName')}
                autoCapitalize="words"
                fullWidth
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Email className={classes.icon} />
              <TextField
                name="email"
                inputRef={register({required: I18n.t('emailIsRequired')})}
                type="email"
                label="Email"
                placeholder="aron@smith.com"
                autoCapitalize="none"
                fullWidth
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Lock className={classes.icon} />
              <TextField
                name="password"
                inputRef={register({required: I18n.t('passwordIsRequired')})}
                label={I18n.t('password')}
                type="password"
                placeholder="**********"
                autoCapitalize="none"
                fullWidth
                required
              />
            </div>
            {/*</div>*/}

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                width: '100%',
                marginBottom: -100,
              }}>
              <Button
                onClick={history.goBack}
                className={classes.registerButton}>
                {I18n.t('back')}
              </Button>
              <Button type="submit" className={classes.button}>
                {I18n.t('createAnAccount')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
