import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';

import I18n from '../translations/i18n';
import client from '../util/fetch';
import {UPDATE_GUEST_IS_FACILITATOR} from '../config/cloudFunctionsUrls';
import {useUser} from '../auth/userContext';

const IsGuestFacilitatorModal = ({
  guestToToggleFacilitator,
  setGuestToToggleFacilitator,
  guests,
  setGuests,
  setNumberOfAttributedLicences,
  setNumberOfAvailableLicences,
}) => {
  const {addToast} = useToasts();

  const me = useUser();

  const toggleIsFacilitator = async () => {
    try {
      const shouldBeFacilitatorNow = !guestToToggleFacilitator?.isFacilitator;
      await client(
        UPDATE_GUEST_IS_FACILITATOR(
          me.uid,
          guestToToggleFacilitator!.email,
          shouldBeFacilitatorNow,
        ),
      );

      const newGuests = guests.map(guest => {
        if (guest.email === guestToToggleFacilitator!.email) {
          return {...guest, isFacilitator: shouldBeFacilitatorNow};
        }
        return guest;
      });

      setGuests(newGuests);

      setNumberOfAttributedLicences(l => l + (shouldBeFacilitatorNow ? 1 : -1));
      setNumberOfAvailableLicences(l => l + (shouldBeFacilitatorNow ? -1 : +1));

      addToast(I18n.t('userUpdated'), {appearance: 'success'});
    } catch (e) {
      console.error(e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setGuestToToggleFacilitator(undefined);
    }
  };

  return (
    <Dialog
      open={Boolean(guestToToggleFacilitator)}
      onClose={() => setGuestToToggleFacilitator(undefined)}>
      <DialogTitle>{_.upperFirst(guestToToggleFacilitator?.email)}</DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}>
          {guestToToggleFacilitator?.isFacilitator
            ? I18n.t('confirmRemoveFacilitatorMode')
            : I18n.t('confirmUpdateToFacilitator')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setGuestToToggleFacilitator(undefined)}
          color="primary">
          {I18n.t('cancel')}
        </Button>
        <Button onClick={toggleIsFacilitator} type="submit" color="primary">
          {I18n.t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IsGuestFacilitatorModal;
