export default {
  errorOccurred: 'An error occurred',
  myAccount: 'My account',
  myStatistics: 'My statistics',
  profilePicture: 'Profile picture',
  notDefined: 'Not defined',
  unknown: 'Unknown',
  saved: 'Saved',
  youMustLogInAgain: 'You must log in again in order to modify you email',
  password: 'Password',
  cancel: 'CANCEL',
  submit: 'SUBMIT',
  terminate: 'TERMINATE',
  confirm: 'Confirm',
  youMustLogInAgainToDeleteYourAccount:
    'You must log in again to delete your account',
  emailModification: 'Email modification',
  accountDeletion: 'Account deletion',
  firstName: 'First name',
  lastName: 'Last name',
  name: 'Name',

  gender: 'Gender',
  firm: 'Firm',
  email: 'Email',
  country: 'Country',
  deleteMyAccount: 'Delete my account',
  logout: 'LOGOUT',
  female: 'Female',
  male: 'Male',
  back: 'Back',
  emailSent: 'Email sent',
  sendEmail: 'SEND THE EMAIL',
  login: 'LOGIN',
  createMyAccount: 'CREATE MY ACCOUNT',
  create: 'Create',
  loading: 'Loading...',
  close: 'CLOSE',
  continue: 'CONTINUE',
  minutes: 'minutes',
  seconds: 'seconds',
  hour: 'hour',
  hourAndHalf: 'hour and half',
  hours: 'hours',
  anErrorOccurred: 'An error occurred',
  sorryAnErrorOccurred: 'Sorry, an error occurred',
  date: 'Date',
  time: 'time',
  times: 'times',
  hello: 'Hello ',
  createAnAccount: 'Create an account',
  incorrectCredentials: 'Incorrect credentials',
  alreadyUsedEmail: 'Already used email',
  invalidEmail: 'Invalid email',
  tooShortPassword: 'Password is too short',
  firstNameIsRequired: 'First name is required',
  lastNameIsRequired: 'Last name is required',
  emailIsRequired: 'Email is required',
  passwordIsRequired: 'Password is required',
  add: 'Add',
  deletionOfUser: 'Deletion of the user',
  userDeletionWarning: 'Be careful! Deleting a user is an irreversible action!',
  userUpdated: 'User updated',

  referent: 'Referent',
  referents: 'Referents',
  referentEmail: 'Referent email (comma-separated)',
  creationDate: 'Creation date',
  firmManagement: 'Firms management',
  userManagement: 'Users management',
  cursorInformationManagement: '(i) management',
  specialCursorInformationManagement: 'Personalized (i) management',
  firmAdded: 'Firm added',
  firmModified: 'Firm modified',
  newUser: 'New user',
  sendAnInvitation: 'Send an invitation',
  adminArea: 'Admin area',
  updateTheFirm: 'Update the firm ',
  numberOfLicences: 'Number of licences',
  attributedLicences: 'Attributed licences: ',
  availableLicences: 'Available licences: ',
  confirmUpdateToFacilitator: 'Confirm passage to facilitator?',
  confirmRemoveFacilitatorMode: 'Confirm removal of facilitator mode?',
  noLicenceAvailableLeft: 'Sorry, no licence available left',
  dropFileHere: 'Drop a CSV file here',
  importACSVFile: 'Import a CSV file',
  noFirmRegistered:
    'You need a firm to add an user, please report us this error',
  registeredUsers: 'Registered users',
  guestsUsers: 'Users who have not yet logged in with their email address',
  emailDomain: 'Email domain',
  level: 'Level',
  hierarchicalLevel: 'Hierarchical level',
  middleManager: 'Middle manager',
  topManagement: 'Top Management',
  upperManagement: 'Upper management',
  operationalManagement: 'Operational management',
  employee: 'Employee',
  noUserWithThisEmail: 'No user with this email',
  pleaseProvideYourEmail:
    'Please enter your email address to reset your password.',
  pleaseProvideLoginDetails: 'Please provide your login details',
  forgottenPassword: 'Forgotten password',
  pleaseProvideInfo: 'Please provide your info',
  chronology: 'Chronology',
  seeGraph: 'See the graph',
  survey: 'Survey',
  surveySubTitle:
    'The facilitator invites you to position yourself on the quality of the exchanges with the following four indicators: ',
  surveyResultsSubTitle:
    'On these four indicators, the survey allows you to evaluate the quality of exchanges in the following way:',
  actedOnOperator: 'acted on operator',
  insufficientNumberOfPoints: 'Insufficient number of points',
  dialogTableChronology: 'Dialog table chronology',
  meetingHistory: 'Meeting history',
  mustBeFacilitator: 'You must be facilitator to access this page',
  showDetails: 'Show details',
  infoAboutMeeting: 'Info about the meeting',
  desiredEffect: 'Desired effect',
  actualDuration: 'Actual duration',
  start: 'Start',
  surveyResults: 'Survey results',
  results: 'Results',
  activated: 'Activated',
  deactivated: 'Deactivated',
  meetingType: 'Meeting type',
  goal: 'Goal',
  findYourMeetingsHere: 'Find here the meetings to which you have been invited',
  meeting: 'Meeting',
  info: 'Info',
  facilitation: 'Facilitation',
  history: 'History',
  startMeeting: 'Start the meeting',
  selectPhoto: 'Select an image',
  chooseFromGallery: 'Choose from the gallery',
  takePicture: 'Take a picture',
  sendTheInvitation: 'SEND THE INVITATION',
  newAttendee: 'New attendee',
  withoutLicense: "Without a license, you can't choose more than ",
  addAttendee: 'ADD AN ATTENDEE',
  next: 'NEXT',
  dialogTables: 'Dialog tables',
  chooseATable: 'Choose a table',
  meetingDeleted: 'Meeting deleted',
  meetingModification: 'Meeting modification',
  createMeeting: 'Create a new meeting',
  deleteMeeting: 'Delete the meeting',
  customTable: 'Custom Table',
  saveAndQuit: 'Save and quit',
  recommendedTables: 'Recommended tables',
  otherTables: 'Other tables',
  createNewDialogTable: 'Create a new dialog table',
  firstCursor: 'First cursor',
  secondCursor: 'Second cursor',
  thirdCursor: 'Third cursor',
  fourthCursor: 'Fourth cursor',
  indicateFirstCursor: 'Indicate the first cursor',
  indicateSecondCursor: 'Indicate the second cursor',
  indicateThirdCursor: 'Indicate the third cursor',
  indicateFourthCursor: 'Indicate the fourth cursor',
  running: 'Running',
  meetingTypeOperational: 'Operational',
  meetingTypeCoordination: 'Coordination',
  meetingTypeStrategic: 'Strategic',
  meetingTypeExploratory: 'Exploratory',
  meetingEffectDecision: 'Decision',
  meetingEffectCritic: 'Critic',
  meetingEffectCreate: 'Create',
  meetingEffectCohesion: 'Cohesion',
  meetingName: 'Meeting name',
  specifyMeetingName: 'Indicate the meeting name',
  schedule: 'Schedule',
  duration: 'Duration',
  indicateGoal: 'Indicate the goal of the meeting',
  type: 'Type',
  effect: 'Effect',
  mode: 'Mode',
  youMuseHaveLicense:
    'Freemium mode - To access all features you must have a facilitator license',
  iWantToBeFacilitator: 'Be meta-facilitator',
  becomeFacilitator: 'Metagora : become a meta-facilitator',
  becomeFacilitatorMessageBody0:
    'Thank you for your interest in Metagora, the application that transforms meetings :) ! ',
  becomeFacilitatorMessageBody1:
    'We have been warned of your ambition to become a meta-facilitator ! We come back to you very quickly :)',
  becomeFacilitatorFooter: 'The Meta-team !',
  freemiumMode: 'Freemium mode',
  chooseTableToStart: 'Choose a dialog table in order to start the meeting',
  addAttendeesToStart: 'Please add attendees in order to start the meeting',
  evaluation: 'Evaluation',
  over3: 'over 3',
  evaluateNietzsche:
    '"To evaluate is to create" said Nietzsche... So be creative!',
  howWouldYouRate: 'How would you rate the quality of this meeting?',
  whatWasDeterminingIndicator:
    'In your opinion, what was the determining indicator of the meeting?',
  whatConstructiveFeedback: 'What constructive feedback can you give?',
  thanks: 'Thanks !',
  presencial: 'Presencial',
  distancial: 'Distancial',
  meetingIsPaused: 'Meeting is paused',
  terminateMeeting: 'Terminate the meeting',
  irreversibleAction: 'Be careful, this action is irreversible',
  areYouSureToTerminate: 'Are you sure you want to end the meeting?',
  errorInaccessibleButtons: 'Error, control buttons inaccessible',
  randomlyChoose:
    'Please choose a number of active participants to be randomly selected',
  randomMode: 'Random mode',
  remaining: '', // Do not change this translation
  remainingSeconds: 'seconds left', // Do not change this translation
  seeResults: 'See the results',
  noVoteForThisCursor: 'No vote for this cursor',
  quartiles: 'quartiles',
  median: 'median',
  meetings: 'Meetings',
  profile: 'Profile',
  cursorDialog: 'Dialog',
  cursorFeelings: 'Feelings',
  cursorMeaning: 'Meaning',
  cursorPresence: 'Presence',
  cursorQuestioning: 'Questioning',
  cursorArgumentation: 'Argumentation',
  cursorHonesty: 'Honesty',
  cursorListening: 'Listening',
  cursorVision: 'Vision',
  cursorDialectic: 'Dialectic',
  cursorExpectations: 'Expectations',
  cursorAwareness: 'Awareness',
  cursorFrustration: 'Frustration',
  cursorJoy: 'Joy',
  cursorHope: 'Hope',
  cursorFears: 'Fears',
  cursorRelevance: 'Relevance',
  cursorPedagogy: 'Pedagogy',
  cursorNuance: 'Nuance',
  cursorBrightness: 'Brightness',
  cursorDialogInformation:
    '"Be ready to put everything back on the table, down to your own convictions"',
  cursorDialogBreakTheDeadlock:
    'have you thought about questioning the unquestioned evidence of the exchanges?',
  cursorFeelingsInformation:
    '"Be attentive to your own emotions as well as those of others"',
  cursorFeelingsBreakTheDeadlock:
    'have you considered asking participants about their current emotional state?',
  cursorMeaningInformation:
    '"Feeling like we\'re involved in something bigger than ourselves"',
  cursorMeaningBreakTheDeadlock:
    'have you thought about re-examining the origins and objectives of the project?',
  cursorPresenceInformation:
    '"Don\'t let yourself be polluted by the outside world"',
  cursorPresenceBreakTheDeadlock:
    'have you thought of formalizing a unifying rite for your exchange (weather, story-telling, disconnection...)?',
  cursorQuestioningInformation:
    '"Be open-minded and ask questions, even if we don\'t like them"',
  cursorQuestioningBreakTheDeadlock:
    'have you thought about questioning the unquestioned evidence of the exchanges?',
  cursorArgumentationInformation:
    '"Support our positions by putting forward our reasons for believing in them"',
  cursorArgumentationBreakTheDeadlock:
    'have you thought about getting participants to justify what they are saying?',
  cursorHonestyInformation:
    '"Talk about what can stand in the way, at the boundaries of the break"',
  cursorHonestyBreakTheDeadlock:
    'do you feel that there is a central issue that has not been addressed?',
  cursorListeningInformation:
    '"Not knowing what I\'m going to say until the other person finishes their sentence."',
  cursorListeningBreakTheDeadlock:
    'have you considered asking participants to clarify or rephrase their thoughts?',
  cursorVisionInformation: '"We have the same representation of the future."',
  cursorVisionBreakTheDeadlock:
    'have you thought about re-clarifying the vision of the future that the collective carries?',
  cursorDialecticInformation: '"We help to be a resource for each other."',
  cursorDialecticBreakTheDeadlock:
    'Have you considered asking participants how each person can contribute to the needs of the group?',
  cursorExpectationsInformation:
    '"Expectations of each other are clearly defined"',
  cursorExpectationsBreakTheDeadlock:
    "have you thought about clarifying everyone's role in the collective synergy?",
  cursorAwarenessInformation:
    '"Our exchanges address the same levels of stakes"',
  cursorAwarenessBreakTheDeadlock:
    'have you considered having participants clarify their understanding of the issues?',
  cursorFrustrationInformation: '"I feel like we could have done more"',
  cursorFrustrationBreakTheDeadlock:
    "have you considered asking each participant's expectations?",
  cursorJoyInformation: '"I feel satisfaction in this collective work"',
  cursorJoyBreakTheDeadlock:
    'have you thought about questioning what is not being done enough in the collective dynamic?',
  cursorHopeInformation: '"I\'m motivated by the collective energy"',
  cursorHopeBreakTheDeadlock:
    'have you thought about asking participants about the purpose of the collective?',
  cursorFearsInformation: '"I feel like we\'re forgetting important things."',
  cursorFearsBreakTheDeadlock:
    'have you thought about re-interviewing participants on the ideas they have set aside?',
  cursorRelevanceInformation:
    '"We\'re responding sufficiently to the challenges we\'ve announced"',
  cursorRelevanceBreakTheDeadlock:
    'have you thought about re-interviewing the origins of the project to shed light on the blind spots?',
  cursorPedagogyInformation:
    '"I\'m able to explain to someone else what we\'re saying to each other"',
  cursorPedagogyBreakTheDeadlock:
    'have you thought about questioning the meaning of words that are used to make sure everyone is talking about the same things?',
  cursorNuanceInformation:
    '"Our proposals take into account the complexity of the issues at stake"',
  cursorNuanceBreakTheDeadlock:
    'have you thought about re-examining the balance of project priorities?',
  cursorBrightnessInformation:
    '"To have a clear perception of the scope of our actions"',
  cursorBrightnessBreakTheDeadlock:
    'Have you thought about getting participants to restate the ins and outs of the project?',
  toBreakTheDeadlock: 'To break the deadlock',
  cursorInfo: 'Cursor information',
  summaryByMail: 'Automatic synthesis by mail',
  myPhiloScore: 'My PhiloScore:',
  mostUsedTables: 'Most used tables',
  mostUsedTypes: 'Most used types',
  noStatsYet: 'Find your stats here after your first facilitated meeting',
  letOthersSpeak: 'Let others have their say...',
  noFacilitatorFeatures:
    'Warning, the web platform does not allow access to your facilitator functionalities',
  cursor: 'Cursor',
  frenchCursorInformation: 'french (i)',
  englishCursorInformation: 'english (i)',
  updateTheCursor: 'Update the cursor ',
  cursorUpdated: 'Cursor updated',
  counter: 'Counter',
  newPersonalizedI: 'New personalized (i)',
  cursorCreated: 'Cursor created',
  cursorCreation: 'Cursor creation',
  cursorDeleted: 'Cursor deleted',
  exportUsersAsCSV: 'Export users as CSV',
  join: 'Join',
  meetingCode: 'Meeting code',
  joinAMeetingByCode: 'Join a meeting with a code',
  code: 'Code',
  noMeetingFoundWithThisCode: 'No meeting found with this code',
  meetingIsTerminated: 'Too late, this meeting is over',
  meetingHasNotStartedYet: 'The meeting has not yet started',
  pleaseEnterYourNames: 'Please provide your first and last names',
  joinAsGuest: 'Guest mode',
};
