import firebase from 'firebase';

import {meetings, users, guests} from '../firebase/firestoreCollections';
import User from './User';
import Guest from './Guest';

export const getAllUsers = async (): Promise<User[]> => {
  const data = await firebase
    .firestore()
    .collection(users)
    .where('email', '!=', null)
    .get();

  // @ts-ignore
  return data.docs.map(d => d.data());
};

export const getAllFacilitators = async (): Promise<User[]> => {
  const data = await firebase
    .firestore()
    .collection(users)
    .where('isFacilitator', '==', true)
    .get();

  // @ts-ignore
  return data.docs.map(d => d.data());
};

export const getAllUsersOfMyFirm = async (): Promise<User[]> => {
  const data = await firebase.firestore().collection(users).get();

  // @ts-ignore
  return data.docs.map(d => d.data());
};

export const createUser = user => {
  return firebase.firestore().collection(users).doc(user.uid).set(user);
};

export const updateUser = (uid, newData) => {
  return firebase.firestore().collection(users).doc(uid).update(newData);
};

export const getUser = uid => {
  return firebase
    .firestore()
    .collection(users)
    .doc(uid)
    .get()
    .then(user => user.data());
};

export const getUserByEmail = async email => {
  const data = await firebase
    .firestore()
    .collection(users)
    .where('email', '==', email)
    .get();

  if (data.docs.length) {
    return data.docs[0].data();
  } else {
    return null;
  }
};

export const getAllUsersByFirm = async (firmUid): Promise<User[]> => {
  const data = await firebase
    .firestore()
    .collection(users)
    .where('firmUid', '==', firmUid)
    .get();
  return data.docs.map(d => d.data() as User);
};

export const getAllGuestsByFirm = async (firmUid): Promise<Guest[]> => {
  const data = await firebase
    .firestore()
    .collection(guests)
    .where('firmUid', '==', firmUid)
    .get();
  return data.docs.map(d => d.data() as Guest);
};

export const updateMyMailsEverywhere = async (
  userUid,
  previousEmail,
  newEmail,
) => {
  const [response] = await Promise.all([
    firebase
      .firestore()
      .collection(meetings)
      .where('attendeesEmail', 'array-contains', previousEmail)
      .get(),
    updateUser(userUid, {email: newEmail}),
  ]);

  const batch = firebase.firestore().batch();

  response.docs.forEach(doc => {
    batch.update(doc.ref, {
      attendeesEmail: firebase.firestore.FieldValue.arrayUnion(newEmail),
    });
    batch.update(doc.ref, {
      attendeesEmail: firebase.firestore.FieldValue.arrayRemove(previousEmail),
    });
  });

  return batch.commit();
};
