import React from 'react';
import Slider from '@material-ui/core/Slider';
import {withStyles} from '@material-ui/core';
import styled from 'styled-components';

import ThumbnailOrCircleLetter from './ThumbnailOrCircleLetter';
import colors from '../theme/colors';

const MySlider = ({
  entry,
  setSliderValue,
  label,
  trackBackgroundColor,
  thumbColor,
  plusColor,
}) => {
  const minus = () => {
    setSliderValue(
      label,
      entry.value * 100 - 10 <= 0 ? 0 : entry.value * 100 - 10,
    );
  };

  const plus = () => {
    setSliderValue(
      label,
      entry.value * 100 + 10 >= 100 ? 100 : entry.value * 100 + 10,
    );
  };

  const ImageThumb = (props: any) => {
    return (
      <span {...props}>
        <ThumbnailOrCircleLetter
          user={entry}
          color={thumbColor || colors.primary}
          style={{cursor: 'pointer', zIndex: 10}}
        />
      </span>
    );
  };

  const ImageSlider = withStyles({
    thumb: {
      height: 60,
      width: 60,
      marginTop: 0,
      marginLeft: -30,
      zIndex: 2,
    },
    track: {
      height: 60,
      color: colors.secondary,
      borderBottomLeftRadius: 30,
      borderTopLeftRadius: 30,
      border: 'solid',
      borderColor: colors.secondary,
      borderWidth: 1,
    },
    rail: {
      color: trackBackgroundColor,
      opacity: 1,
      height: 60,
      borderRadius: 30,
      border: 'solid',
      borderColor: colors.secondary,
      borderWidth: 1,
    },
  })(Slider);

  return (
    <>
      <Minus onClick={minus}>-</Minus>

      <ImageSlider
        ThumbComponent={ImageThumb}
        defaultValue={entry.value * 100}
        onChangeCommitted={(e, val) => {
          setSliderValue(label, val);
        }}
      />

      <Plus onClick={plus} plusColor={plusColor}>
        +
      </Plus>
    </>
  );
};

export default MySlider;

const Minus = styled.span`
  position: absolute;
  z-index: 1;
  font-size: 60px;
  line-height: 92px;
  color: white;
  cursor: pointer;
`;

const Plus = styled.span`
  position: absolute;
  z-index: 1;
  font-size: 60px;
  line-height: 92px;
  right: ${props => (props.plusColor === 'white' ? '30px' : '16px')};
  color: ${props => props.plusColor || colors.secondary};
  cursor: pointer;
`;
