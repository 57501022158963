import React from 'react';

export default () => {
  return (
    <html>
      <head>
        <meta
          charSet="utf-8"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <style
          media="screen"
          dangerouslySetInnerHTML={{
            __html: `body { background: #ECEFF1; color: rgba(0,0,0,0.87); font-family: Roboto, Helvetica, Arial, sans-serif; margin: 0; padding: 0; }
              #message { background: white; max-width: 360px; margin: 100px auto 16px; padding: 32px 24px; border-radius: 3px; }
              #message h2 { color: #ffa100; font-weight: bold; font-size: 16px; margin: 0 0 8px; }
              #message h1 { font-size: 22px; font-weight: 300; color: rgba(0,0,0,0.6); margin: 0 0 16px;}
              #message p { line-height: 140%; margin: 16px 0 24px; font-size: 14px; }
              #message a { display: block; text-align: center; background: #039be5; text-transform: uppercase; text-decoration: none; color: white; padding: 16px; border-radius: 4px; }
              #message, #message a { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
              #load { color: rgba(0,0,0,0.4); text-align: center; font-size: 13px; }
              @media (max-width: 600px) {
              body, #message { margin-top: 0; background: white; box-shadow: none; }
              body { border-top: 16px solid #ffa100; }`,
          }}></style>

        <title>Metagora : Politique de confidentialité</title>
      </head>

      <body>
        <div id="message">
          <h1>Politique de confidentialité</h1>
          <h2>Gestion des données personnelles</h2>
          <p>
            Notre engagement de transparence et responsabilité : Toutes vos
            données sont conservées en toute confidentialité et sécurité, ou
            supprimées si nous ne les utilisons pas. Les particuliers sont
            informés qu'ils sont réputés avoir d'ores et déjà donné leur
            consentement à l'utilisation de leurs données, du seul fait de
            l'utilisation de l’application mobile Metagora, dans les conditions
            fixées au paragraphe « Utilisation des données » ci-dessous. Ils
            peuvent toutefois, à tout moment et sous les modalités prévues au
            présent article, s'opposer à ce que leurs données soient utilisées.
            Pour toutes questions supplémentaires comme la demande de la
            suppression de vos données, merci de vous adresser au responsable
            des données personnelles : Jean MATHY
          </p>
          <h2>Inventaire des données - Données collectées :</h2>

          <p>
            Les données éventuellement collectées sont : • Prénom
            <br />
            • Nom
            <br />
            • Genre (facultatif)
            <br />
            • Pays (facultatif)
            <br />
            • Société (facultatif pour les particuliers)
            <br />
            • Niveau hiérarchique (facultatif pour les particuliers)
            <br />
            • E-Mail
            <br />• Avatar (facultatif)
          </p>
          <h2>Utilisation des données :</h2>
          <p>
            Les traitements de données à caractère personnel mis en œuvre ont
            pour stricte finalité :
            <br />
            • L’identification des personnes sur l’application Metagora,
            notamment pour faciliter la lisibilité des autres personnes
            connectées au service
            <br />
            o Utilise : Le Prénom, le Nom, le Genre si indiqué, l’Avatar si
            fourni.
            <br />
            • L’authentification des utilisateurs sur l’application
            <br />
            o Utilise : L’email
            <br />
            • La personnalisation de l’affichage en fonction du profil de
            l’utilisateur
            <br />o Utilise : Le Pays, la Société, le Niveau hiérarchique.
          </p>
          <h2>Partage et conservation de vos informations</h2>
          <p>
            Aucune de vos données ne sera partagée, vendue, louée à des tiers à
            aucun moment aujourd'hui ou dans le futur. Cependant certaines
            informations peuvent être partagées à des sociétés avec lesquelles
            nous travaillons comme : •Sociétés Web : Hébergeurs de site Web,
            agences marketing et partenaires pour la publicité.
            <br />
            •Autorités d'Etat comme la police pour les fraudes notamment ou la
            CNIL agissant par ses pouvoirs d'enquête,
            <br />
            Toutes les informations sont sécurisées par chiffrement. Elles
            seront supprimées si nous ne les utilisons pas dans le délai légal
            maximal, ou si vous en formulez expressément la demande selon les
            modalités ci-après définies. La demande de suppression peut être
            formulée à tout moment. Toutefois, en cas de fraude ou de saisie par
            les autorités d'Etat, vos données seront conservées pour des raisons
            légales.
          </p>
          <h2>Marketing & Publicité</h2>
          <p>
            Vous recevrez de notre part avec votre autorisation des emails et/ou
            SMS concernant l'actualité de la marque comme les nouveautés ou
            encore l'annonce de promotions sur le site. Vous pouvez bien sûr
            décider de ne plus recevoir de messages de notre part en cliquant
            sur le lien “désabonnement” à la fin du message. Ou en nous
            contactant : contact@noeticbees.com
          </p>
          <h2>Vos droits, protéger votre confidentialité et cookies</h2>
          <p>
            A propos de vos informations personnelles, vous disposez des droits
            suivants : – Le droit de refuser, dès l'origine, que vos données
            personnelles soient traitées, conservées ou utilisées
            <br />
            – Le droit d'être informé sur l'utilisation de vos informations
            personnelles– Le droit d'accéder à vos informations personnelles
            <br />
            – Le droit de demander la correction de vos informations
            personnelles erronées
            <br />
            – Le droit de demander à ce que nous supprimions vos données, ou que
            nous arrêtions de les traiter ou de les collecter, à raison de
            circonstances particulières (nous contacter)
            <br />
            – Le droit d'arrêter les messages Marketing
            <br />
            – Le droit de demander le transfert de vos données vers un autre
            fournisseur de service
            <br />
            – Le droit d'introduire un recours auprès de votre régulateur de
            protection des données (en France, celui-ci est la CNIL, Commission
            nationale de l'informatique et des libertés)
            <br />
            Si vous désirez exercer vos droits ou simplement poser des
            questions, veuillez contacter directement le responsable de
            traitement, Jean Mathy à l’adresse : contact@noeticbees.com
            <br />
            Les Cookies nous permettent de récolter les données des utilisateurs
            afin de vous faciliter la navigation et l'expérience digitale (comme
            mettre en cache les photos pour optimiser la vitesse du site sur
            votre appareil). Ou par exemple nous permettre de les utiliser à des
            fins publicitaires.
            <br />A aucun moment, ces données sont ou ne seront transmises à des
            tiers.
          </p>
        </div>
      </body>
    </html>
  );
};
