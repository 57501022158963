import React from 'react';

import colors from '../theme/colors';

const MyCustomToast = ({appearance, children}) => (
  <div
    style={{
      background: appearance === 'error' ? colors.error : colors.primary,
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 4,
      color: 'white',
      fontWeight: 'bold',
      marginTop: 3,
    }}>
    {children}
  </div>
);

export default MyCustomToast;
