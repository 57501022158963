import React, {useState} from 'react';
import firebase from 'firebase';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {createUser} from '../users/usersActions';
import {useUserDispatch} from './userContext';
import useWindowSize from '../util/useWindowSize';
import I18n from '../translations/i18n';
import colors from '../theme/colors';
import {Button, TextField} from '@material-ui/core';
import useStyles from './useStyles';
import {useForm} from 'react-hook-form';
import routes from '../navigation/routes';

const AnonymousNameForm = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const history = useHistory();
  const userDispatch = useUserDispatch();

  const {watch, register} = useForm();
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const [isLoading, setIsLoading] = useState(false);

  const useSubmit = async () => {
    setIsLoading(true);
    console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++');
    await createUser({
      uid: firebase.auth().currentUser.uid,
      isAnonymous: true,
      firstName,
      lastName,
      isFacilitator: false,
      creationDate: new Date(),
    });
    console.log('=========================================');
    userDispatch({firstName, lastName});
    history.push(routes.home);
    setIsLoading(false);
  };

  const maxWidth = windowSize.width > 800 ? 750 : windowSize.width - 50;

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: maxWidth,
        }}>
        <Circle />
        <BlueText>{I18n.t('pleaseEnterYourNames')}</BlueText>

        <TextField
          placeholder="Aron"
          name="firstName"
          inputRef={register({required: I18n.t('firstNameIsRequired')})}
          label={I18n.t('firstName')}
          autoCapitalize="words"
          style={{marginLeft: 25, paddingRight: 10}}
        />

        <TextField
          placeholder="Smith"
          label={I18n.t('lastName')}
          name="lastName"
          inputRef={register({required: I18n.t('lastNameIsRequired')})}
          autoCapitalize="words"
          style={{marginLeft: 25, paddingRight: 10}}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
          }}>
          {/*<GrayButton*/}
          {/*  onPress={history.goBack}*/}
          {/*  text={I18n.t('back')}*/}
          {/*  style={{width: 120}}*/}
          {/*/>*/}

          <SubmitButton
            variant="contained"
            className={classes.button}
            onClick={useSubmit}
            disabled={!firstName || !lastName || isLoading}>
            {I18n.t('submit')}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default AnonymousNameForm;

const BlueText = styled.p`
  color: ${colors.primary};
  font-family: Colonel, sans-serif;
  font-size: 34px;
  padding: 25px;
  margin-right: 15px;
`;

const Circle = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${colors.secondary};
  border-radius: 10px;
  margin: 20px;
  position: absolute;
  right: 0;
  top: 20px;
`;

const SubmitButton = styled(Button)`
  width: 120px;
  margin-top: 50px;
  align-self: flex-end;
  margin-right: 10px;
`;
