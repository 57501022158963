export default {
  errorOccurred: "Une erreur s'est produite",
  myAccount: 'Mon compte',
  myStatistics: 'Mes statistiques',
  profilePicture: 'Photo de profil',
  notDefined: 'Non défini',
  unknown: 'Inconnu',
  saved: 'Enregistré',
  youMustLogInAgain:
    'Vous devez vous reconnecter pour pouvoir modifier votre email',
  youMustLogInAgainToDeleteYourAccount:
    'Vous devez vous reconnecter pour pouvoir supprimer votre compte',
  password: 'Mot de passe',
  cancel: 'ANNULER',
  submit: 'VALIDER',
  terminate: 'TERMINER',
  confirm: 'Confirmer',
  emailModification: "Changement d'email",
  accountDeletion: 'Suppression de compte',
  firstName: 'Prénom',
  lastName: 'Nom',
  name: 'Nom',
  email: 'Email',
  emailSent: 'Email envoyé',
  login: 'SE CONNECTER',
  forgottenPassword: 'Mot de passe oublié',
  loading: 'Loading...',
  close: 'FERMER',
  back: 'Retour',
  gender: 'Genre',
  selectPhoto: 'Sélectionner une photo',
  chooseFromGallery: 'Choisir depuis la galerie',
  takePicture: 'Prendre une photo',
  next: 'SUIVANT',
  continue: 'CONTINUER',
  minutes: 'minutes',
  seconds: 'secondes',
  hour: 'heure',
  hourAndHalf: 'heure et demie',
  hours: 'heures',
  anErrorOccurred: "Une erreur s'est produite",
  sorryAnErrorOccurred: "Désolé, une erreur s'est produite",
  date: 'Date',
  time: 'fois',
  times: 'fois',
  hello: 'Bonjour ',
  phoneNumber: 'Numéro de téléphone ',
  address: 'Adresse ',
  createAnAccount: 'Créer un compte',
  incorrectCredentials: 'Identifiants incorrects',
  alreadyUsedEmail: 'Cette addresse email est déjà utilisée',
  invalidEmail: 'Email invalide',
  tooShortPassword: 'Mot de passe trop court',
  firstNameIsRequired: 'Prénom requis',
  lastNameIsRequired: 'Nom requis',
  emailIsRequired: 'Email requis',
  passwordIsRequired: 'Mot de passe requis',
  userDeleted: 'Utilisateur supprimé',
  invitationSent: 'Invitation envoyée',
  add: 'Ajouter',
  deletionOfUser: "Suppression de l'utilisateur",
  userUpdated: 'Utilisateur modifié',
  userDeletionWarning:
    "Attention ! La suppresion d'un utilisateur est une action irréversible !",

  referent: 'Référent',
  referents: 'Référents',
  referentEmail: 'Email des référents (séparés par des virgules)',
  creationDate: 'Date de création',
  firmManagement: 'Gestion des entreprises',
  userManagement: 'Gestion des utilisateurs',
  cursorInformationManagement: 'Gestion des (i) par défaut',
  specialCursorInformationManagement: 'Gestion des (i) personnalisés',
  firmAdded: 'Entreprise ajoutée',
  newUser: 'Nouvel utilisateur',
  sendAnInvitation: 'Envoyer une invitation',
  newFirm: 'Nouvelle entreprise',
  firmModified: 'Entreprise modifiée',
  adminArea: "Espace d'administration",
  updateTheFirm: "Modifier l'entreprise ",
  numberOfLicences: 'Nombre de licences',
  attributedLicences: 'Nombre de licences attribuées : ',
  availableLicences: 'Licences disponibles : ',
  confirmUpdateToFacilitator: 'Confirmer le passage en facilitateur ?',
  confirmRemoveFacilitatorMode:
    'Confirmer le suppression du mode facilitateur ?',
  noLicenceAvailableLeft: "Désolé, vous n'avez plus de licence disponible",
  dropFileHere: 'Glisser/déposer un fichier CSV',
  importACSVFile: 'Importer un fichier CSV',
  noFirmRegistered:
    'Vous devez avoir une entreprise associée à votre compte pour ajouter un utilisateur, veuillez-nous reporter cette erreur',
  registeredUsers: 'Utilisateurs ayant un compte Metagora',
  guestsUsers: "Utilisateurs ne s'étant pas encore connectés avec cet email",
  emailDomain: "Nom de domaine de l'email",
  firm: 'Entreprise',
  level: 'Niveau',
  hierarchicalLevel: 'Niveau hiérarchique',
  country: 'Pays',
  deleteMyAccount: 'Supprimer mon compte',
  logout: 'SE DÉCONNECTER',
  female: 'Féminin',
  male: 'Masculin',
  middleManager: 'Cadre intermédiaire',
  topManagement: 'Top Management',
  upperManagement: 'Direction',
  operationalManagement: 'Management opérationnel',
  employee: 'Employé',
  noUserWithThisEmail: 'Aucun utilisateur avec cette adresse mail',
  pleaseProvideYourEmail:
    'Veuillez rentrer votre adresse mail pour mettre à jour votre mot de passe.',
  sendEmail: "ENVOYER L'EMAIL",
  pleaseProvideLoginDetails:
    'Merci de bien vouloir renseigner vos identifiants',
  pleaseProvideInfo: 'Merci de bien vouloir renseigner vos informations',
  createMyAccount: 'CRÉER MON COMPTE',
  create: 'Créer',
  chronology: 'Chronologie',
  seeGraph: 'Voir le graphique',
  survey: 'Sondage',
  surveySubTitle:
    'Le facilitateur vous invite à vous positionner sur la qualité des échanges avec les quatre indicateurs suivants : ',
  surveyResultsSubTitle:
    'Sur ces quatre indicateurs, le sondage vous permet d’évaluer la qualité des échanges de la manière suivante :',
  actedOnOperator: "a agi sur l'opérateur",
  insufficientNumberOfPoints:
    'Nombre de points insuffisant pour tracer un graphique',
  dialogTableChronology: 'Chronologie de la table de dialogue',
  meetingHistory: 'Historique des réunions',
  showDetails: 'Afficher les détails',
  infoAboutMeeting: 'Informations sur la réunion',
  attendees: 'participants',
  desiredEffect: 'Effet recherché',
  actualDuration: 'Durée effective',
  start: 'Début',
  surveyResults: 'Résultats du sondage',
  results: 'Résultats',
  activated: 'Activé',
  deactivated: 'Desactivé',
  meetingType: 'Type de réunion',
  goal: 'Objectif',
  findYourMeetingsHere:
    'Retrouvez ici les réunions auxquelles vous avez été invité',
  meeting: 'Réunion',
  info: 'Informations',
  facilitation: 'Facilitation',
  history: 'Historique',
  startMeeting: 'Démarrer la réunion',
  sendTheInvitation: "Envoyer l'invitation",
  newAttendee: 'Nouveau participant',
  withoutLicense: 'Sans licence, vous ne pouvez pas choisir plus de ',
  addAttendee: 'AJOUTER UN NOUVEAU PARTICIPANT',
  dialogTables: 'Tables de dialogue',
  chooseATable: 'Choisissez une table',
  meetingDeleted: 'Réunion supprimée',
  meetingModification: 'Modification de la réunion',
  createMeeting: 'Créer une nouvelle réunion',
  deleteMeeting: 'Supprimer la réunion',
  customTable: 'Table personnalisée',
  saveAndQuit: 'Enregistrer et quitter',
  recommendedTables: 'Tables recommandées',
  otherTables: 'Autres tables',
  createNewDialogTable: 'Créer une nouvelle table',
  firstCursor: 'Premier curseur',
  secondCursor: 'Deuxième curseur',
  thirdCursor: 'Troisième curseur',
  fourthCursor: 'Quatrième curseur',
  indicateFirstCursor: 'Indiquez le nom du premier curseur',
  indicateSecondCursor: 'Indiquez le nom du second curseur',
  indicateThirdCursor: 'Indiquez le nom du troisième curseur',
  indicateFourthCursor: 'Indiquez le nom du quatrième curseur',
  running: 'En cours',
  meetingTypeOperational: 'Opérationnelle',
  meetingTypeCoordination: 'Coordination',
  meetingTypeStrategic: 'Stratégique',
  meetingTypeExploratory: 'Exploratoire',
  meetingEffectDecision: 'Décision',
  meetingEffectCritic: 'Critique',
  meetingEffectCreate: 'Créer',
  meetingEffectCohesion: 'Cohésion',
  meetingName: 'Nom de la réunion',
  indicateMeetingName: 'Indiquez le nom de la réunion',
  schedule: 'Horaire',
  duration: 'Durée',
  indicateGoal: "Indiquez l'objectif de la réunion",
  type: 'Type',
  effect: 'Effet',
  mode: 'Mode',
  mustBeFacilitator:
    'Vous devez avoir le statut de facilitateur pour avoir accès à cette page',
  youMuseHaveLicense:
    'Mode freemium - Pour accèder à toutes les fonctionnalités vous devez avoir une licence facilitateur',
  iWantToBeFacilitator: 'Devenir meta-facilitateur',
  becomeFacilitator: 'Metagora : devenir meta-facilitateur',
  becomeFacilitatorMessageBody0:
    'Merci de votre intérêt pour Metagora, l’application qui transforme les réunions :) ! ',
  becomeFacilitatorMessageBody1:
    'Nous avons été prévenu de votre ambition : devenir meta-facilitateur ! Nous revenons vers vous très rapidement :)',
  becomeFacilitatorFooter: 'La Meta-team !',
  freemiumMode: 'Mode Freemium',
  chooseTableToStart:
    'Veuillez choisir une table de dialogue afin de pouvoir démarrer la réunion',
  addAttendeesToStart:
    'Veuillez ajouter des participants afin de pouvoir démarrer la réunion',
  evaluation: 'Évaluation',
  step: 'Étape',
  over3: 'sur 3',
  evaluateNietzsche:
    '«Évaluer, c’est créer » disait Nietzsche… Sois donc créateur !',
  howWouldYouRate: 'Comment évalues-tu la qualité de cette réunion ?',
  whatWasDeterminingIndicator:
    "Selon toi, quel a été l'indicateur déterminant de la réunion ?",
  whatConstructiveFeedback: 'Quel feedback constructif peux-tu faire ?',
  thanks: 'Merci !',
  presencial: 'Présentiel',
  distancial: 'Distanciel',
  meetingIsPaused: 'La réunion est en pause',
  terminateMeeting: 'Terminer la réunion',
  irreversibleAction: 'Attention, cette action est irréversible',
  areYouSureToTerminate: 'Êtes-vous sûr de vouloir terminer la réunion ?',
  errorInaccessibleButtons: 'Erreur, boutons de contrôle inaccessibles',
  randomlyChoose:
    'Veuillez choisir un nombre de participants actifs qui seront aléatoirement choisis',
  randomMode: 'Mode aléatoire',
  remaining: 'Il reste', // Do not change this translation
  remainingSeconds: 'secondes', // Do not change this translation
  seeResults: 'Voir les résultats',
  noVoteForThisCursor: 'Aucun vote pour ce curseur',
  quartiles: 'quartilles',
  median: 'médiane',
  meetings: 'Réunions',
  profile: 'Profil',
  cursorDialog: 'Dialogue',
  cursorFeelings: 'Émotions',
  cursorMeaning: 'Sens',
  cursorPresence: 'Présence',
  cursorQuestioning: 'Questionnement',
  cursorArgumentation: 'Argumentation',
  cursorHonesty: 'Franchise',
  cursorListening: 'Écoute',
  cursorVision: 'Vision',
  cursorDialectic: 'Dialectique',
  cursorExpectations: 'Attentes',
  cursorAwareness: 'Niveau de conscience',
  cursorFrustration: 'Frustration',
  cursorJoy: 'Joie',
  cursorHope: 'Espoir',
  cursorFears: 'Craintes',
  cursorRelevance: 'Pertinence',
  cursorPedagogy: 'Pédagogie',
  cursorNuance: 'Nuance',
  cursorBrightness: 'Clarté',
  cursorDialogInformation:
    '«Être prêt à tout re-poser sur la table, jusqu’à ses propres convictions»',
  cursorDialogBreakTheDeadlock:
    'as-tu pensé à interroger les évidences non-questionnées des échanges?',
  cursorFeelingsInformation:
    '«Être attentif à ses propres émotions comme à celles des autres»',
  cursorFeelingsBreakTheDeadlock:
    'as-tu pensé interroger les participants sur leur état émotionnel du moment?',
  cursorMeaningInformation:
    '«Avoir le sentiment d’être impliqué dans quelque chose qui nous dépasse»',
  cursorMeaningBreakTheDeadlock:
    'as-tu pensé à réinterroger les origines et les objectifs du projet?',
  cursorPresenceInformation:
    '«Ne pas se laisser polluer par le monde extérieur»',
  cursorPresenceBreakTheDeadlock:
    'as-tu pensé à formaliser un rite fédérateur pour votre échange (météo, story-telling, déconnexion…)?',
  cursorQuestioningInformation:
    '« Avoir l’esprit ouvert et se poser des questions, même si elles ne nous ne plaisent pas »',
  cursorQuestioningBreakTheDeadlock:
    'as-tu pensé à interroger les évidences non-questionnées des échanges?',
  cursorArgumentationInformation:
    '« Soutenir nos positions en mettant en avant nos raisons d’y croire »',
  cursorArgumentationBreakTheDeadlock:
    'as-tu pensé à amener les participants à justifier de ce qu’ils avancent ?',
  cursorHonestyInformation:
    '« Parler de ce qui peut faire obstacle, aux frontières de la rupture »',
  cursorHonestyBreakTheDeadlock:
    'as-tu le sentiment qu’il y a un sujet central qui n’a pas été abordé?',
  cursorListeningInformation:
    '« Ne pas savoir ce que je vais dire avant que l’autre ait terminé sa phrase »',
  cursorListeningBreakTheDeadlock:
    'as-tu pensé à demander aux participants de clarifier ou reformuler leurs pensées?',
  cursorVisionInformation: '«Nous avons la même représentation de l’avenir »',
  cursorVisionBreakTheDeadlock:
    'as-tu pensé à re-clarifier la vision du futur que le collectif porte?',
  cursorDialecticInformation:
    '«Nous contribuons à être ressources les uns pour les autres»',
  cursorDialecticBreakTheDeadlock:
    'as-tu pensé à demander aux participants en quoi chacun peut nourrir les besoins du collectif?',
  cursorExpectationsInformation:
    '«Les attentes des uns envers les autres sont clairement définies»',
  cursorExpectationsBreakTheDeadlock:
    'as-tu pensé à clarifier les rôles de chacun dans la synergie collective?',
  cursorAwarenessInformation:
    '«Nos échanges adressent les mêmes niveaux d’enjeux»',
  cursorAwarenessBreakTheDeadlock:
    'as-tu pensé à faire clarifier aux participants leur compréhension des enjeux?',
  cursorFrustrationInformation:
    '«J’ai le sentiment qu’on aurait pu faire plus»',
  cursorFrustrationBreakTheDeadlock:
    'as-tu pensé à interroger les attentes de chacun des participants?',
  cursorJoyInformation:
    '«J’éprouve de la satisfaction dans ce travail collectif»',
  cursorJoyBreakTheDeadlock:
    'as-tu pensé à interroger ce qui ne se fait pas assez dans la dynamique collective?',
  cursorHopeInformation: '«Je suis motivé par l’énergie collective»',
  cursorHopeBreakTheDeadlock:
    'as-tu pensé à interroger les participants sur la raison d’être du collectif?',
  cursorFearsInformation:
    '«J’ai le sentiment que nous oublions des choses importantes»',
  cursorFearsBreakTheDeadlock:
    'as-tu pensé à ré-interroger les participants sur les idées qu’ils ont mis de côté?',
  cursorRelevanceInformation:
    '«Nous répondons suffisamment aux enjeux annoncés»',
  cursorRelevanceBreakTheDeadlock:
    'as-tu pensé à réinterroger les origines du projet pour en éclairer les angles mort?',
  cursorPedagogyInformation:
    '«Je suis capable d’expliquer à quelqu’un d’autre ce que nous nous disons»',
  cursorPedagogyBreakTheDeadlock:
    'as-tu pensé à interroger le sens des mots qui sont utilisés pour t’assurer que tout le monde parle bien des mêmes choses?',
  cursorNuanceInformation:
    '«Nos propositions tiennent compte de la complexité des enjeux»',
  cursorNuanceBreakTheDeadlock:
    'as-tu pensé à réinterroger l’équilibre des priorités du projet?',
  cursorBrightnessInformation:
    '«Avoir une claire perception de la portée de nos actions»',
  cursorBrightnessBreakTheDeadlock:
    'as-tu pensé à faire reformuler aux participants les tenants et les aboutissants du projet?',
  toBreakTheDeadlock: 'Pour sortir de l’impasse',
  cursorInfo: 'Informations sur le curseur',
  summaryByMail: 'Synthèse automatique par mail',
  myPhiloScore: 'Mon PhiloScore :',
  mostUsedTables: 'Tables les plus utilisées',
  mostUsedTypes: 'Types les plus utilisées',
  noStatsYet:
    'Retrouvez vos statistiques ici après votre première réunion facilitée',
  letOthersSpeak: 'La parole est aux autres...',
  noFacilitatorFeatures:
    "Attention, la plateforme web ne permet pas d'accéder à vos fonctionnalités facilitateur",
  cursor: 'Curseur',
  frenchCursorInformation: '(i) français',
  englishCursorInformation: '(i) anglais',
  updateTheCursor: 'Mettre à jour le curseur ',
  cursorUpdated: 'Curseur modifié',
  counter: 'Compteur',
  newPersonalizedI: 'Nouveau (i) personnalisé',
  cursorCreated: 'Curseur créé',
  cursorCreation: "Création d'un nouveau curseur",
  cursorDeleted: 'Curseur supprimé',
  exportUsersAsCSV: 'Export CSV des utilisateurs',
  meetingCode: 'Code de la réunion',
  joinAMeetingByCode: 'Utiliser un code de réunion',
  code: 'Code',
  join: 'Rejoindre',
  noMeetingFoundWithThisCode: 'Aucune réunion associée à ce code',
  meetingIsTerminated: 'Trop tard, cette réunion est terminée',
  meetingHasNotStartedYet: "La réunion n'a pas encore démarré",
  joinAsGuest: 'Mode invité',
  pleaseEnterYourNames: 'Veuillez renseigner vos nom et prénom',
};
